import React from "react";
import { withRouter } from "react-router-dom";
import * as Icon from "react-feather";
import queryString from "query-string";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, parseDate } from "react-day-picker/moment";
import moment from "moment";
import { getServices } from "service-fetch";
import ReactTooltip from "react-tooltip";

import Page from "../../../components/common/Page";
import Header from "../../../components/common/Header";
import Action from "../../../components/common/Action";
import Filters from "../../../components/common/Filters";
import List from "../../../components/common/List";
import Alert from "../../../components/common/Alert";
import SearchInput from "../../../components/common/SearchInput";
import SignUpDetailsModal from "./SignUpDetailsModal";

import GAService from "../../../services/GAService";
import UserService from "../../../services/UserService";
import ToastService from "../../../services/ToastService";

import { SORT_ORDER, STATUS } from "../../../constants/CommonConstants";
import { SIGN_UP_COLUMNS, SIGN_UP_STATES, SIGN_UP_STATE_FILTERS } from "../../../constants/SignUpConstants";

const { Sign_upsService: SignUpService } = getServices();

import AppConfig from "../../../config/app/web-app.config.js";

import "./sign-ups.css";

class SignUps extends React.Component {
	constructor(props) {
		super(props);

		let { stateFilter, searchTerm, sortField, sortOrder, startDate, endDate } = queryString.parse(this.props.location.search);

		if (startDate) {
			startDate = moment(startDate).toDate();
		}
		if (!startDate) {
			startDate = moment()
				.subtract(30, "days")
				.toDate();
		}

		if (endDate) {
			endDate = moment(endDate).toDate();
		}
		if (!endDate) {
			endDate = moment()
				.add(45, "days")
				.toDate();
		}

		this.state = {
			loading: false,
			loadedAll: true,
			data: [],

			stateFilter: stateFilter ? stateFilter : Object.keys(SIGN_UP_STATE_FILTERS)[0],
			startDate: startDate,
			endDate: endDate,
			searchTerm: searchTerm || "",
			sortField: sortField || "id",
			sortOrder: sortOrder || SORT_ORDER.desc,
			limit: 50,
			pageSize: 50,

			showSignUpDetails: false,
			selectedSignUp: null,
			resendSignUp: false,
			showConfirmDeleteModal: false,
			showConfirmUpdateStateModal: false
		};
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });

		this.resetComponent();
	}

	resetComponent = async () => {
		let { limit } = this.state;

		await this.update({
			loading: true
		});

		let data = await this.fetchData();

		await this.update({
			loading: false,
			loadedAll: data ? data.length < limit : true
		});
	};

	fetchData = async () => {
		let { searchTerm, stateFilter, startDate, endDate, sortField, sortOrder, limit } = this.state;

		let { data, error } = await SignUpService.fetchSignUps({
			query: {
				searchTerm,
				states: stateFilter && stateFilter === SIGN_UP_STATE_FILTERS.all.id ? [] : [stateFilter],
				startDate: startDate,
				endDate: endDate,
				sortField,
				sortOrder,
				limit
			}
		});

		if (error) {
			ToastService.error("Error fetching sign ups. Please try again.");
			this.update({ loading: false, data: [] });
			return;
		}

		if (!data) {
			ToastService.info("No Sign Ups found.");
			this.update({ loading: false, data: [] });
			return;
		}

		await this.update({
			data
		});
		return data;
	};

	onSearchChange = async value => {
		await this.update({
			searchTerm: value
		});
		this.setUrlParams();
		await this.fetchData();
	};

	onLoadMore = async () => {
		let { limit, pageSize } = this.state;

		await this.update({
			limit: limit + pageSize
		});

		await this.fetchData();
	};

	onRecordClicked = item => {};

	getHeaders() {
		let headers = {
			items: SIGN_UP_COLUMNS,
			sortBy: this.sortBy
		};

		return headers;
	}

	sortBy = async sortField => {
		let { sortOrder } = this.state;
		sortOrder = sortOrder === SORT_ORDER.asc ? SORT_ORDER.desc : SORT_ORDER.asc;
		await this.update({ sortField, sortOrder });
		this.setUrlParams();
		await this.fetchData();
	};

	onStateSelected = async item => {
		await this.update({ stateFilter: item.id });
		this.setUrlParams();
		await this.fetchData();
	};

	isStateSelected = item => {
		return item === this.state.stateFilter;
	};

	getFilters() {
		let stateFilters = Object.keys(SIGN_UP_STATE_FILTERS)
			.map(item => {
				return { id: item, value: SIGN_UP_STATE_FILTERS[item].display, order: SIGN_UP_STATE_FILTERS[item].order };
			})
			.sort((a, b) => a.order - b.order);

		let filters = {
			filters: {
				title: "State",
				items: stateFilters,
				onClick: this.onStateSelected,
				isSelected: this.isStateSelected
			}
		};
		return filters;
	}

	handleFromChange = async startDate => {
		// Change the from date and focus the "to" input field
		await this.update({ startDate });
		this.setUrlParams();
		this.fetchData();
	};
	handleToChange = async endDate => {
		await this.update({ endDate });
		this.showFromMonth;
		this.setUrlParams();
		this.fetchData();
	};
	showFromMonth() {
		const { startDate, endDate } = this.state;
		if (!startDate) {
			return;
		}
		if (moment(endDate).diff(moment(startDate), "months") < 2) {
			this.datePickerTo.getDayPicker().showMonth(startDate);
		}
	}

	setUrlParams() {
		if (!this.props.history) {
			return;
		}

		let { stateFilter, searchTerm, sortField, sortOrder, startDate, endDate } = this.state;

		let params = {};

		if (stateFilter) {
			params.stateFilter = stateFilter;
		}

		if (searchTerm) {
			params.searchTerm = searchTerm;
		}

		if (sortField) {
			params.sortField = sortField;
		}

		if (sortOrder) {
			params.sortOrder = sortOrder;
		}

		if (startDate) {
			params.startDate = startDate;
		}

		if (endDate) {
			params.endDate = endDate;
		}

		params = new URLSearchParams(params);
		this.props.history.replace({
			pathname: this.props.location.pathname,
			search: params.toString()
		});
	}

	onConfirmDelete = recordData => {
		this.update({
			selectedSignUp: recordData,
			showConfirmDeleteModal: true,
			resendSignUp: false
		});
	};

	onConfirmDeleteClose = async confirm => {
		let { selectedSignUp } = this.state;

		if (confirm && selectedSignUp) {
			let name = selectedSignUp.legal_business_name;
			await SignUpService.updateSignUp({
				params: {
					signUpId: selectedSignUp.id
				},
				body: {
					status: STATUS.deleted
				}
			});

			ToastService.info(`Deleted Sign Up for ${name}`);
			this.fetchData();
		}

		this.update({
			selectedSignUp: null,
			showConfirmDeleteModal: false
		});
	};

	onConfirmUpdateState = (recordData, resendSignUp = false) => {
		this.update({
			selectedSignUp: recordData,
			resendSignUp,
			showConfirmUpdateStateModal: true
		});
	};

	onConfirmUpdateStateClose = async confirm => {
		let { selectedSignUp, resendSignUp } = this.state;

		if (!confirm || !selectedSignUp) {
			this.update({
				selectedSignUp: null,
				showConfirmUpdateStateModal: false,
				resendSignUp: false
			});

			return;
		}

		let state = selectedSignUp.state;

		// Determine the new state depending on the current state
		if (resendSignUp) {
			state = SIGN_UP_STATES.sent;
		} else if (state === SIGN_UP_STATES.draft) {
			state = SIGN_UP_STATES.sent;
		} else if (state === SIGN_UP_STATES.signed) {
			state = SIGN_UP_STATES.submitted;
		} else {
			return;
		}

		let name = selectedSignUp.legal_business_name;
		await SignUpService.updateSignUp({
			params: {
				signUpId: selectedSignUp.id
			},
			body: {
				state
			}
		});

		ToastService.info(`Updated Sign Up for ${name}`);
		this.fetchData();

		this.update({
			selectedSignUp: null,
			showConfirmUpdateStateModal: false,
			resendSignUp: false
		});
	};

	onPreviewSignUp = recordData => {
		window.open(AppConfig.SIGN_UP_URL + `order/${recordData.public_id}`, "_blank").focus();
	};

	onShowSignUpDetails = recordData => {
		this.update({
			selectedSignUp: recordData,
			showSignUpDetails: true
		});
	};

	renderRecord = recordData => {
		try {
			let locationFields = recordData.location_fields;

			return [
				recordData.legal_business_name,
				<div className="msu-state__text">{recordData.state}</div>,
				recordData.contact_name,
				recordData.contact_phone,
				recordData.contact_email,
				locationFields.state_prov,
				locationFields.country,
				// use icon
				recordData.is_free_trial ? "Yes" : "No",
				recordData.start_date,
				this.renderActions(recordData)
			];
		} catch (error) {
			console.log(error);
		}
		return null;
	};

	renderActions = recordData => {
		let state = recordData.state;
		let canManage = UserService.isSuperAdminOrCustomerSuccessOrAccountOwner();

		if (!canManage) {
			return null;
		}

		return (
			<div className="dh-list__actions">
				<Action
					key={`edit-${recordData.id}`}
					id={`edit-${recordData.id}`}
					label={"Edit Sign Up"}
					icon={Icon.Edit}
					onClick={() => this.props.history.push(`/customer-success/sign-ups/${recordData.id}`)}
				/>
				<Action
					key={`delete-${recordData.id}`}
					id={`delete-${recordData.id}`}
					label={"Delete"}
					icon={Icon.Trash2}
					onClick={() => this.onConfirmDelete(recordData)}
				/>
				{state === SIGN_UP_STATES.draft ? (
					<Action
						key={`Send-${recordData.id}`}
						id={`Send-${recordData.id}`}
						label={"Send Sign Up"}
						icon={Icon.Send}
						onClick={() => this.onConfirmUpdateState(recordData)}
					/>
				) : state === SIGN_UP_STATES.sent ? (
					<Action
						key={`Send-${recordData.id}`}
						id={`Send-${recordData.id}`}
						label={"Re-Send Sign Up"}
						icon={Icon.Send}
						onClick={() => this.onConfirmUpdateState(recordData, true)}
					/>
				) : state === SIGN_UP_STATES.signed ? (
					<Action
						key={`Sign-${recordData.id}`}
						id={`Sign-${recordData.id}`}
						label={"Submit Sign Up"}
						icon={Icon.LogIn}
						onClick={() => this.onConfirmUpdateState(recordData)}
					/>
				) : null}
				<Action
					key={`Details-${recordData.id}`}
					id={`Details-${recordData.id}`}
					label={"See Details"}
					icon={Icon.MoreHorizontal}
					onClick={() => this.onShowSignUpDetails(recordData)}
				/>
				<Action
					key={`Preview-${recordData.id}`}
					id={`Preview-${recordData.id}`}
					label={"Preview"}
					icon={Icon.Globe}
					onClick={() => this.onPreviewSignUp(recordData)}
				/>
			</div>
		);
	};

	renderDateRangeFilter = () => {
		let { startDate, endDate } = this.state;

		const modifiers = { dateRangeStart: startDate, endDate: endDate };

		return (
			<div className="msu__date-range">
				<div className="msu__date-range__text" data-tip data-for="date-range-selector-rtt">
					Date Range <Icon.Info size={13} />
					<ReactTooltip id="date-range-selector-rtt" className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="bottom">
						Filter on Start Date
					</ReactTooltip>
				</div>
				<div className="input-group">
					<div className="InputFromTo">
						<DayPickerInput
							value={startDate}
							placeholder={" From"}
							format="LL"
							formatDate={formatDate}
							parseDate={parseDate}
							dayPickerProps={{
								selectedDays: [startDate, { from: startDate, to: endDate }],
								disabledDays: { after: endDate },
								toMonth: endDate,
								modifiers,
								numberOfMonths: 2,
								onDayClick: () => this.datePickerTo.getInput().focus()
							}}
							onDayChange={this.handleFromChange}
						/>{" "}
						<span>
							<DayPickerInput
								ref={el => (this.datePickerTo = el)}
								value={endDate}
								placeholder={" To"}
								format="LL"
								formatDate={formatDate}
								parseDate={parseDate}
								dayPickerProps={{
									selectedDays: [startDate, { from: startDate, to: endDate }],
									disabledDays: { before: startDate },
									modifiers,
									month: startDate,
									fromMonth: startDate,
									numberOfMonths: 2
								}}
								onDayChange={this.handleToChange}
							/>
						</span>
					</div>
				</div>
			</div>
		);
	};

	render = () => {
		let {
			data,
			loading,
			loadedAll,
			searchTerm,
			sortField,
			sortOrder,
			selectedSignUp,
			showConfirmDeleteModal,
			showConfirmUpdateStateModal,
			showSignUpDetails
		} = this.state;

		let canCreate = UserService.isSuperAdminOrCustomerSuccessOrAccountOwner();

		return (
			<Page>
				<Header title="Sign Ups">
					{canCreate && (
						<Action
							id="create-sign-up"
							label={"Create Sign Up"}
							icon={Icon.Plus}
							onClick={() => this.props.history.push(`/customer-success/sign-ups/create`)}
						/>
					)}
				</Header>
				<div className="Common__search">
					<SearchInput placeholder={"Search ..."} onChange={this.onSearchChange} initValue={searchTerm} />
				</div>
				{this.renderDateRangeFilter()}

				<Filters filters={this.getFilters()} />

				<List
					items={data}
					loading={loading}
					loadedAll={loadedAll}
					sortField={sortField}
					sortOrder={sortOrder}
					headers={this.getHeaders()}
					renderRecord={this.renderRecord}
					onRecordClicked={this.onRecordClicked}
					onLoadMore={this.onLoadMore}
					noDataTitle={"No sign ups found..."}
					noDataIcon={<Icon.AlertCircle />}
				/>

				<Alert type="info" show={showConfirmDeleteModal} title={"Delete Sign Up"} confirm={"Yes"} cancel={"No"} onClose={this.onConfirmDeleteClose}>
					Are you sure you want to delete the Sign Up for {selectedSignUp ? selectedSignUp.legal_business_name : "this"}?
				</Alert>
				<Alert
					type="info"
					show={showConfirmUpdateStateModal}
					title={"Update State Sign Up"}
					confirm={"Yes"}
					cancel={"No"}
					onClose={this.onConfirmUpdateStateClose}
				>
					{selectedSignUp && selectedSignUp.state === SIGN_UP_STATES.draft ? (
						<div>Are you sure you want to send the Sign Up to {selectedSignUp ? selectedSignUp.legal_business_name : "this business"}?</div>
					) : selectedSignUp && selectedSignUp.state === SIGN_UP_STATES.signed ? (
						<div>Are you sure you want to submit {selectedSignUp ? selectedSignUp.legal_business_name : "this business"}'s Sign Up?</div>
					) : selectedSignUp && selectedSignUp.state === SIGN_UP_STATES.sent ? (
						<div>Are you sure you want to re-send {selectedSignUp ? selectedSignUp.legal_business_name : "this business"}'s Sign Up?</div>
					) : null}
				</Alert>
				<SignUpDetailsModal
					show={showSignUpDetails}
					signUpId={selectedSignUp ? selectedSignUp.id : null}
					onClose={() => this.update({ showSignUpDetails: false })}
				></SignUpDetailsModal>
			</Page>
		);
	};
}

export default withRouter(SignUps);
