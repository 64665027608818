import React, { Component } from "react";
import * as Icon from "react-feather";
import { PoseGroup } from "react-pose";
import queryString from "query-string";

import UserService from "../../services/UserService";
import CompanyService from "../../services/CompanyService";

import NotificationService from "../../services/NotificationService";
import ContactService from "../../services/ContactService";

import { ContextMenu } from "../../constants/Messenger";

import "./location-selector.css";

class LocationSelector extends Component {
	constructor(props) {
		super(props);
		this.state = {
			companyId: null,
			location: null,
			locations: [],
			showList: false,
			count: { locations: {} }
		};
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	async componentDidMount() {
		NotificationService.subscribeOnce("contactCountUpdate", "locationSelector_componentDidMount", this.updateContactCount);
		document.addEventListener("mousedown", this.onMouseDown, false);
		await this.resetComponent();
		await this.forceSetLocation();

		NotificationService.subscribeOnce("companyChanged", "locationSelector_component", () => {
			this.resetComponent();
		});

		NotificationService.subscribeOnce("locationChanged", "locationSelector_component", () => {
			this.resetComponent();
		});

		NotificationService.subscribeOnce("locationUpdated", "locationSelector_component", () => {
			this.resetComponent();
		});

		NotificationService.subscribeOnce("locationCreated", "locationSelector_component", () => {
			this.resetComponent();
		});
	}

	componentWillUnmount() {
		document.removeEventListener("mousedown", this.onMouseDown, false);
	}

	async resetComponent() {
		let company = UserService.getActiveCompany();
		let location = UserService.getActiveLocation();

		let locations = await CompanyService.fetchLocations(company.id);
		let count = await ContactService.getCachedContactCount();

		await this.update({
			companyId: company.id,
			location,
			locations,
			count
		});
	}

	forceSetLocation() {
		let { locations } = this.state;

		try {
			let url = window.location.href;

			if (url.indexOf("messenger") !== -1) {
				let { query } = queryString.parseUrl(url);
				let { locationId } = query;

				if (locationId && locationId.length > 0) {
					let [deepLinkLocation] = locations.filter(location => location.public_id === locationId);
					this.onSelect(deepLinkLocation);
				}
			}
		} catch (error) {
			console.log(error);
		}
	}

	updateContactCount = count => {
		this.update({ count });
	};

	onMouseDown = async e => {
		let { showList } = this.state;

		if (this.context && this.context.contains && this.context.contains(e.target)) {
			return;
		}

		if (this.contextContainer && this.contextContainer.contains && this.contextContainer.contains(e.target) && !showList) {
			await this.update({
				showList: true
			});
		} else if (this.context && this.context.contains) {
			await this.update({
				showList: false
			});
		}
	};

	async onSelect(location) {
		if (!location) {
			return;
		}

		UserService.setActiveLocation(location);

		await this.update({ location, showList: false });

		NotificationService.publish("locationChanged", location);
	}

	anyOpenConversations() {
		let { count } = this.state;

		for (let locationId of Object.keys(count.locations)) {
			if (count.locations[locationId] > 0) {
				return true;
			}
		}

		return false;
	}

	renderLocations() {
		let { locations, count } = this.state;

		let isSuperAdminOrCustomerSuccess = UserService.isSuperAdminOrCustomerSuccess();

		return (
			<ContextMenu key="container" ref={ref => (this.context = ref)} className="mb-location-selector-list">
				{locations.map(location => {
					let locationOpenCount = count.locations[location.id] || 0;

					let locationName = location.name;
					let locationId = location.id;

					let locationAddress1 = location.address_1;
					let locationCity = location.city;
					let locationAdditionalInformation = `${locationAddress1}, ${locationCity}`;

					return (
						<div key={location.id} className="mb-location-selector-item" onClick={() => this.onSelect(location)}>
							<div className="mb-location-selector-item-name">
								{locationName}
								{isSuperAdminOrCustomerSuccess ? ` (${locationId})` : ""}
								<div className="mb-location-selector-item__additional-information">{locationAdditionalInformation}</div>
							</div>

							{locationOpenCount > 0 && <div className="mb-location-selector-item--unread">{locationOpenCount}</div>}
						</div>
					);
				})}
			</ContextMenu>
		);
	}

	render() {
		let { showList, location, companyId } = this.state;

		if (!location) {
			return "";
		}

		let isSuperAdminOrCustomerSuccess = UserService.isSuperAdminOrCustomerSuccess();
		let locationName = location.name;
		let locationId = location.id;

		return (
			<div ref={ref => (this.contextContainer = ref)} className="mb-location-selector">
				<div className="mb-location-selector-icon">
					<Icon.MapPin size="16" />
					{this.anyOpenConversations() && <div className="mb-location-selector-item--unread-red-dot" />}
				</div>
				<div className="mb-location-selector-name">
					{isSuperAdminOrCustomerSuccess ? `(${locationId}) ` : ""}
					{locationName}
				</div>
				<PoseGroup>{showList && this.renderLocations()}</PoseGroup>
			</div>
		);
	}
}

export default LocationSelector;
