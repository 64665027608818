import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import moment from "moment";
import * as Icon from "react-feather";
import { Collapse } from "react-bootstrap";

import Spinners from "./Spinners";
import List from "./List";

import { CONFIRM_BLOCK_COLUMNS_APPOINTMENTS } from "../../constants/ConfirmBlock";

import "../../styles/css/components/commons/confirm-block.css";
class ConfirmBlockAppointment extends Component {
	constructor(props) {
		super(props);
		let { t } = this.props;

		this.state = {
			loading: typeof this.props.loading !== "undefined" ? this.props.loading : false,
			show: typeof this.props.show !== "undefined" ? this.props.show : true,
			header: typeof this.props.header !== "undefined" ? this.props.header : t("Are you sure you would like to continue?"),
			subHeader: typeof this.props.subHeader !== "undefined" ? this.props.subHeader : "",
			showBackButton: typeof this.props.showBackButton !== "undefined" ? this.props.showBackButton : true,
			showNextButton: typeof this.props.showNextButton !== "undefined" ? this.props.showNextButton : true,
			backButtonLabel: this.props.backLabel ? this.props.backLabel : t("Back"),
			nextButtonLabel: this.props.nextLabel ? this.props.nextLabel : t("Confirm"),

			submitError: typeof this.props.submitError !== "undefined" ? this.props.submitError : false,
			errorHeader: this.props.errorHeader ? this.props.errorHeader : t("Error"),
			errorMessage: this.props.errorHeader ? this.props.errorHeader : t("An error occurred. Please try again."),
			errorBtnLabel: this.props.errorBtnLabel ? this.props.errorBtnLabel : t("OK"),

			submitSuccess: typeof this.props.submitSuccess !== "undefined" ? this.props.submitSuccess : false,
			successHeader: this.props.successHeader ? this.props.successHeader : t("Success!"),
			successMessage: this.props.successMessage ? this.props.successMessage : t("The action has been successful"),
			successBtnLabel: this.props.successBtnLabel ? this.props.successBtnLabel : t("OK"),
			showFeedback: this.props.showFeedback ? this.props.showFeedback : false,
			showSuccessFeedback: false,
			showFailFeedback: false,
			successFeedbackLabel: this.props.successFeedbackLabel ? this.props.successFeedbackLabel : t("Successfully saved"),
			errorFeedbackLabel: this.props.errorFeedbackLabel ? this.props.errorFeedbackLabel : t("Failed to save")
		};
	}

	async componentDidUpdate(prevProps) {
		if (prevProps.successMessage !== this.props.successMessage) {
			await this.update({ successMessage: this.props.successMessage });
		}
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	handleGenericEventHandler(event) {
		this.setState({ [event.target.name]: event.target.value });
	}

	resetStates = () => {
		let { t } = this.props;

		this.setState({
			loading: false,
			show: true,
			submitError: false,
			submitSuccess: false,
			errorMessage: t("An error occurred. Please try again.")
		});
	};

	updateStates = async props => {
		let { t } = this.props;

		await this.update({
			loading: typeof props.loading !== "undefined" ? props.loading : false,
			show: typeof props.show !== "undefined" ? props.show : true,
			submitError: typeof props.submitError !== "undefined" ? props.submitError : false,
			submitSuccess: typeof props.submitSuccess !== "undefined" ? props.submitSuccess : false,
			errorMessage: typeof props.errorMessage !== "undefined" ? props.errorMessage : t("An error occurred. Please try again."),
			showNextButton: typeof props.showNextButton !== "undefined" ? props.showNextButton : true
		});
	};

	next = () => {
		this.resetStates();
		if (this.props.next) {
			this.props.next();
		}
	};

	confirm = () => {
		let { confirmBtnDisabled } = this.props;

		if (confirmBtnDisabled) {
			return;
		}

		if (this.props.confirm) {
			this.props.confirm();
		}
	};

	back = () => {
		this.resetStates();
		if (this.props.back) {
			this.props.back();
		}
	};

	async shouldComponentUpdate(nextProps, nextState) {
		let shouldUpdate = false;
		if (nextProps.show !== this.props.show) {
			shouldUpdate = true;
		} else if (nextProps.loading !== this.props.loading) {
			shouldUpdate = true;
		} else if (nextProps.submitError !== this.props.submitError) {
			shouldUpdate = true;
		} else if (nextProps.submitSuccess !== this.props.submitSuccess) {
			shouldUpdate = true;
		} else if (nextProps.errorMessage !== this.props.errorMessage) {
			shouldUpdate = true;
		} else if (nextProps.showNextButton !== this.props.showNextButton) {
			shouldUpdate = true;
		}

		if (shouldUpdate) {
			await this.updateStates(nextProps);
		}
		return shouldUpdate;
	}

	getHeaders = () => {
		let headers = {
			items: CONFIRM_BLOCK_COLUMNS_APPOINTMENTS,
			sortBy: this.sortBy
		};

		return headers;
	};

	onRecordClicked = () => {
		// nothing
	};

	createNameTipLabel = record => {
		let { t } = this.props;

		let result = ``;

		if (record.note) {
			result = `${record.note}`;
		}

		result += `${record.note ? " | " : ""}${t("Phone")}: ${record.phone ? record.phone : t("none")}`;
		result += ` | ${t("Email")}: ${record.email ? record.email : t("none")}`;

		if (record.otherMedium) {
			result += ` | ${t("Other")}: ${record.otherMedium}`;
		}

		return result;
	};

	renderSuccessRecord = record => {
		let { t } = this.props;

		try {
			return [
				<div className="dh-tip" tip={this.createNameTipLabel(record)}>
					{record.name}
				</div>,
				<div>{typeof record.state !== "undefined" && <div>{record.state}</div>}</div>,
				<span className="cb-report-success_color">
					<Icon.Check size="18" />
				</span>
			];
		} catch (error) {
			console.log(error.message);
		}
		return null;
	};

	renderFailedRecord = record => {
		let { t } = this.props;

		try {
			return [
				<div className={`dh-tip`} tip={this.createNameTipLabel(record)}>
					{record.name ? record.name : t("Unknown")}
				</div>,
				<div className={"dh-tip"} tip={record.errorNote ? record.errorNote : ""}>
					{record.error ? record.error : t("Error")} <Icon.Info size="14" />
				</div>,
				<span className="cb-report-fail_color">
					<Icon.X size="18" />
				</span>
			];
		} catch (error) {
			console.log(error.message);
		}
		return null;
	};

	renderFeedback() {
		let { successFeedback, errorFeedback, t } = this.props;
		let { successFeedbackLabel, errorFeedbackLabel } = this.state;

		return (
			<div className="cb-container">
				<hr />
				<div className="text-center">
					<h4>{t("Report")}</h4>
				</div>
				<div className="cb-feedback-section">
					<div>
						<button
							className="btn btn-default cb-feedback-section-button"
							onClick={() => this.setState({ showSuccessFeedback: !this.state.showSuccessFeedback })}
						>
							<span>{successFeedbackLabel}</span>
							<span>
								<span className="cb-feedback-section-numbers">
									{successFeedback.length}/{successFeedback.length + errorFeedback.length}
								</span>{" "}
								<span className="cb-feedback-section-icon cb-feedback-section-icon__check">
									<Icon.CheckCircle />
								</span>
							</span>
						</button>
					</div>
					<div>
						<Collapse in={this.state.showSuccessFeedback}>
							<div>
								{successFeedback && successFeedback.length > 0 ? (
									<List
										items={successFeedback}
										loading={false}
										loadedAll={true}
										headers={this.getHeaders()}
										renderRecord={this.renderSuccessRecord}
										onRecordClicked={this.onRecordClicked}
										noDataTitle={t("None succeeded")}
									/>
								) : (
									<h4 className="cb-feedback-no-results">{t("None succeeded")}</h4>
								)}
							</div>
						</Collapse>
					</div>
				</div>
				<div className="cb-feedback-section">
					<div>
						<button className="btn btn-default cb-feedback-section-button" onClick={() => this.setState({ showFailFeedback: !this.state.showFailFeedback })}>
							<span>{errorFeedbackLabel}</span>
							<span>
								<span className="cb-feedback-section-numbers">
									{errorFeedback.length}/{successFeedback.length + errorFeedback.length}
								</span>{" "}
								<span className="cb-feedback-section-icon cb-feedback-section-icon__x-circle">
									<Icon.XCircle />
								</span>
							</span>
						</button>
					</div>
					<div>
						<Collapse in={this.state.showFailFeedback}>
							<div>
								{errorFeedback && errorFeedback.length > 0 && errorFeedback[0].allFailed ? (
									<h4 className="cb-report-feedback-all-failed">
										{t("All failed to be saved")}. <br />
										{errorFeedback[0].message ? errorFeedback[0].message : ""}
									</h4>
								) : errorFeedback && errorFeedback.length > 0 ? (
									<List
										items={errorFeedback}
										loading={false}
										loadedAll={true}
										headers={this.getHeaders()}
										renderRecord={this.renderFailedRecord}
										onRecordClicked={this.onRecordClicked}
										noDataTitle={"None succeeded"}
										noDataIcon={<Icon.AlertCircle />}
									/>
								) : (
									<h4 className="cb-feedback-no-results">{t("None failed")}</h4>
								)}
							</div>
						</Collapse>
					</div>
				</div>
			</div>
		);
	}

	render() {
		let {
			backButtonLabel,
			nextButtonLabel,
			header,
			subHeader,
			show,
			loading,
			submitSuccess,
			submitError,
			showNextButton,
			showBackButton,
			successHeader,
			successMessage,
			successBtnLabel,
			errorHeader,
			errorMessage,
			errorBtnLabel,
			showFeedback
		} = this.state;

		let { confirmBtnDisabled } = this.props;

		if (loading) {
			return (
				<div className="Common__spinnerdiv--center">
					<Spinners loading={true} type="tail-fade" size="120px" />
				</div>
			);
		}
		if (!show) {
			return <div />;
		}

		if (submitSuccess) {
			return (
				<div className="text-center">
					<h2>
						<b>{successHeader}</b>
					</h2>
					<div>
						<Icon.CheckCircle size="64" />
					</div>
					<h4 className="cb-submit-message">{successMessage}</h4>
					<div className="cb-submit__button">
						<div className="mb-button mb-button--fit" onClick={this.next}>
							{successBtnLabel}
						</div>
					</div>
					{showFeedback && this.renderFeedback()}
				</div>
			);
		}

		if (submitError) {
			return (
				<div className="text-center">
					<h2>
						<b>{errorHeader}</b>
					</h2>
					<div>
						<Icon.XCircle size="64" />
					</div>
					<h4 className="cb-submit-message">{errorMessage}</h4>
					<div className="cb-submit__button">
						<button className="mb-button mb-button--fit" onClick={this.next}>
							{errorBtnLabel}
						</button>
					</div>
					{showFeedback && this.renderFeedback()}
				</div>
			);
		}

		return (
			<div className={`${show ? "" : "hidden"}`}>
				<h3 className="text-center">{header}</h3>
				<p className="text-center">{subHeader}</p>
				<div>{this.props.children}</div>
				<div className="invites-bulkUploading-buttons">
					{showBackButton && (
						<div className="mb-button mb-button--fit" onClick={this.back}>
							{backButtonLabel}
						</div>
					)}
					{showNextButton && (
						<div className={`mb-button mb-button--fit ${confirmBtnDisabled ? "mb-button--disabled" : ""}`} onClick={this.confirm}>
							{nextButtonLabel}
						</div>
					)}
				</div>
			</div>
		);
	}
}

export default withTranslation(null, { withRef: true })(ConfirmBlockAppointment);
