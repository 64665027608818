import React, { Component } from "react";
import { AnalyticsService } from "../../services/AnalyticsService";
import UserService from "../../services/UserService";
import ReactTooltip from "react-tooltip";
import * as Icon from "react-feather";

import withLocation from "../../components/common/WithLocation";

class AverageRating extends Component {
	state = { avgRating: 0 };
	async fetchAvgRatings() {
		try {
			const locationId = UserService.getActiveLocation().id;
			const avgRating = await AnalyticsService.getAvgRatingsForLocation({ locationId });
			this.setState({ avgRating });
		} catch (error) {
			console.log(error);
			this.setState({ avgRating: 0 });
		}
	}

	componentDidMount() {
		this.fetchAvgRatings();
	}

	onLocationChanged = () => {
		this.fetchAvgRatings();
	};

	render() {
		const { avgRating } = this.state;
		const { cardName, isEnabled, isPermissible } = this.props;

		let ratingBad = parseFloat(avgRating) < 3.3;

		return (
			<>
				<div className="dashboard__card__header">
					<div className="dashboard__card__header__icon">
						{ratingBad && <Icon.ThumbsDown size="42" />}
						{!ratingBad && <Icon.ThumbsUp size="42" />}
					</div>
					<div className="dashboard__card__header__name dashboard__card__header__name--margin-left">
						{cardName ? cardName : "Avg. Rating"} <Icon.Info size={20} data-tip data-for="avg-rating-all-sites" />
						<ReactTooltip id="avg-rating-all-sites" className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="right">
							<div className="text-left">Average rating across all sites.</div>
						</ReactTooltip>
					</div>
				</div>
				<div className="dashboard__card__stat">
					{isEnabled() && isPermissible() ? (
						<>
							<div className="dashboard__card__stat__count">{avgRating}</div>
							<small>updated nightly</small>
						</>
					) : (
						`${isEnabled() ? "Reviews are not enabled. Contact support to get started." : "Review Data Restricted."}`
					)}
				</div>
			</>
		);
	}
}

export default withLocation(AverageRating);
