import React from "react";
import { withTranslation } from "react-i18next";

import Page from "../../components/common/Page";
import Header from "../../components/common/Header";
import Tabs from "../../components/common/Tabs";
import Tab from "../../components/common/Tab";

import SpecialHours from "./SpecialHours";
import ScheduleHours from "./ScheduleHours";

import GAService from "../../services/GAService";
import ReengagementService from "../../services/ReengagementService";

import { SCHEDULE } from "../../constants/LocationConstants";
import { HOUR_TABS } from "../../constants/Settings";
import { GA_CATEGORIES, GA_ACTIONS } from "../../constants/GAConstants";

class Hours extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedTab: HOUR_TABS.regular.id
		};
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
	}

	onTabSelect = tab => {
		GAService.GAEvent({
			category: GA_CATEGORIES.settings.sections.hours,
			action: GA_ACTIONS.generic.tabSelect,
			label: `Selected tab: ${tab.value}`
		});
		this.setState({ selectedTab: tab.id });
	};

	render() {
		let { selectedTab } = this.state;
		let { t } = this.props;

		return (
			<Page>
				<Header title={t("Hours")} />
				<Tabs onSelect={this.onTabSelect} selected={selectedTab}>
					<Tab id={HOUR_TABS.regular.id} value={HOUR_TABS.regular.value} />
					<Tab id={HOUR_TABS.holiday.id} value={HOUR_TABS.holiday.value} />
					<Tab id={HOUR_TABS.messaging.id} value={HOUR_TABS.messaging.value} />
					{ReengagementService.canViewReenagements() && <Tab id={HOUR_TABS.reengagements.id} value={HOUR_TABS.reengagements.value} />}
				</Tabs>
				{HOUR_TABS.regular.id === selectedTab && <ScheduleHours type={SCHEDULE.types.regularBusinessHours} />}
				{HOUR_TABS.holiday.id === selectedTab && <SpecialHours />}
				{HOUR_TABS.messaging.id === selectedTab && <ScheduleHours type={SCHEDULE.types.messagingHours} />}
				{HOUR_TABS.reengagements.id === selectedTab && <ScheduleHours type={SCHEDULE.types.reengagementSendTime} />}
			</Page>
		);
	}
}

export default withTranslation(null, { withRef: true })(Hours);
