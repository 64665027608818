import React, { PureComponent } from "react";
import moment from "moment";
import * as Icon from "react-feather";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import { Pin } from "lucide-react";

import { EMOJI_POSITIONS } from "../../../../constants/Emojis";
import { DIRECTION, KEYS, MEDIA_TYPES, STATUS_EVENTS } from "../../../../constants/Messenger";

import MessagesService from "../../../../services/MessagesService";
import UtilityService from "../../../../services/UtilityService";
import UserService from "../../../../services/UserService";
import TeamChatService from "../../../../services/TeamChatService";
import RealtimeService from "../../../../services/WebsocketsConnection";
import ToastService from "../../../../services/ToastService";
import EmojiService from "../../../../services/EmojiService";

import Reactions from "./Reactions";
import MessageEvent from "../MessageEvent/MessageEvent";
import ThreadMedia from "../Media/ThreadMedia";
import Linkify from "../../../../components/common/Linkify";

import "./team-chat-message.css";

class TeamChatMessage extends PureComponent {
	constructor(props) {
		super(props);

		let { message, previousMessage, searchTerm } = props;

		this.context = null;

		this.state = {
			message: message,
			previousMessage: previousMessage,
			isMedia: false,
			media: [],
			isContext: false,
			deleted: false,

			isReply: message.reply_id ? true : false,
			isReplyMedia: message.reply_media_id ? true : false,
			replyMedia: message.reply_media_object,

			isEdit: false,
			editedMessageText: "",
			originalMessageText: "",
			enableEditSave: false,
			editSaveLoading: false,

			retryingSend: false,
			searchTerm: searchTerm
		};

		this.editMessageInput = null;

		this.reactionButton = null;
		this.reactionsComponent = null;
		this.reactionsComponentContainer = null;

		this.messageRef = null;
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	async componentDidMount() {
		document.addEventListener("mousedown", this.onClick, false);

		await this.buildMediaList();

		this.scrollIntoView();
	}

	scrollIntoView(options = { behavior: "smooth", block: "center", inline: "end" }) {
		let { isReference } = this.props;
		if (isReference && this.messageRef) {
			this.messageRef.scrollIntoView(options);
		}
	}

	componentWillUnmount() {
		document.removeEventListener("mousedown", this.onClick, false);
	}

	buildMediaList = async () => {
		let { message } = this.state;

		let isMedia = message && message.media && message.media.length > 0;

		if (!isMedia) {
			return;
		}

		await this.update({
			media: message.media,
			isMedia
		});
	};

	onClick = e => {
		if (this.context && this.context.contains && this.context.contains(e.target)) {
			return;
		}

		if (this.emojiSelector && this.emojiSelector.contains && this.emojiSelector.contains(e.target)) {
			return;
		}

		this.update({
			isContext: false,
			showEmojiSelector: false
		});
	};

	onContextClick = () => {
		let { isContext } = this.state;

		this.update({
			isContext: !isContext
		});
	};

	onCreateTaskMessageClick = () => {
		if (this.props.onCreateTaskMessageClick) {
			this.props.onCreateTaskMessageClick();
		}

		this.update({
			isContext: false
		});
	};

	onDeleteMessageClick = () => {
		let { message } = this.state;
		let { id } = message;

		let content = (
			<div className="mb-toast-undo">
				<div>Message deleted!</div>
				<div className="mb-toast-undo-button" onClick={() => (toastInstance = null)}>
					{" "}
					Undo{" "}
				</div>
			</div>
		);

		let toastInstance = toast.info(content, {
			position: "bottom-center",
			autoClose: 5000,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			className: "mb-toast",
			onClose: async () => {
				if (toastInstance) {
					await MessagesService.deleteMessage(id);
					await RealtimeService.sendMessageUpdated(id);
				} else {
					this.update({
						deleted: false
					});
				}
			}
		});

		this.update({
			deleted: true,
			isContext: false
		});
	};

	onEditMessage = async () => {
		let { message } = this.state;

		await this.update({
			isContext: false,
			isEdit: true,
			originalMessageText: message.content,
			editedMessageText: message.content
		});

		if (this.editMessageInput) {
			this.editMessageInput.focus();
		}
	};

	onEditInputScroll = event => {
		// Prevent the scroll event from bubbling up to the thread. That can cause issues with scrolling at times
		event.stopPropagation();
	};

	onReplyToMessage = async () => {
		let { message } = this.state;
		if (this.props.onReplyToMessageClicked) {
			await this.props.onReplyToMessageClicked(message.id);
		}

		await this.update({
			isContext: false
		});
	};

	onKeyDown = e => {
		let user = UserService.get();
		if (!user.messenger_click_to_send && e.keyCode === KEYS.enter && e.shiftKey === false) {
			this.onEditSave();
		}
	};

	onEditSave = async () => {
		let { enableEditSave, message, editedMessageText, editSaveLoading } = this.state;

		if (!enableEditSave || editSaveLoading) {
			return;
		}

		await this.update({
			editSaveLoading: true
		});

		let success = await TeamChatService.updateMessage(message.id, editedMessageText);

		if (!success) {
			await this.update({
				editSaveLoading: false
			});

			toast.info("Unable to update message.", {
				position: "bottom-center",
				autoClose: 5000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				className: "mb-toast"
			});

			return;
		}

		let newMessage = message;

		try {
			if (!newMessage.status_events) {
				newMessage.status_events = [];
			}

			if (typeof newMessage.status_events === "string") {
				newMessage.status_events = JSON.parse(newMessage.status_events);
			}

			let user = UserService.get();

			message.status_events.push({
				event: STATUS_EVENTS.messageEdited.id,
				created_at: moment().toISOString(),
				userId: user.id,
				userName: UserService.getCurrentUserFullName()
			});

			message.status_events = JSON.stringify(message.status_events);
		} catch (error) {
			console.log(`TeamchatMessage: Error parsing message status events - ${error.stack}`);
		}

		RealtimeService.sendMessageUpdated(message.id);

		await this.update({
			editSaveLoading: false,
			isEdit: false,
			message: newMessage
		});

		toast.info("Message updated!", {
			position: "bottom-center",
			autoClose: 5000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			className: "mb-toast"
		});
	};

	onEditCancel = async () => {
		let { editSaveLoading } = this.state;

		if (editSaveLoading) {
			return;
		}

		await this.update({
			isEdit: false
		});
	};

	onEditMessageChange = event => {
		let { originalMessageText } = this.state;
		let { value } = event.target;

		let enableEditSave = value.length > 0 && value !== originalMessageText;

		this.update({
			editedMessageText: value,
			enableEditSave
		});
	};

	onReactionClick = () => {
		EmojiService.openEmojiSelector({
			positionBeside: this.reactionButton,
			position: EMOJI_POSITIONS.left,
			onEmojiSelect: this.onEmojiSelected
		});
	};

	onEmojiSelected = emoji => {
		if (this.reactionsComponent) {
			this.reactionsComponent.onAddOrRemoveReaction(emoji.native);
		}
	};

	onMarkAsUnread = async () => {
		let { message } = this.state;
		let user = UserService.get();

		let success = await TeamChatService.markAsUnread(message.id, message.conversation_id, user.id);

		this.update({
			isContext: false
		});

		if (!success) {
			ToastService.error("An error occurred trying to mark the message as unread.");
			return;
		}

		if (this.props.onMarkAsUnread) {
			this.props.onMarkAsUnread(message);
		}
	};

	onPinMessage = async () => {
		let { message } = this.state;
		let user = UserService.get();

		let success = await MessagesService.pinMessage({
			userId: user.id,
			conversationId: message.conversation_id,
			messageId: message.id
		});

		this.update({
			isContext: false
		});

		if (!success) {
			ToastService.error("An error occurred trying to pin the message.");
			return;
		}

		if (this.props.onPinMessage) {
			this.props.onPinMessage(message);
		}
	};

	onEnter = e => {
		if (e.keyCode === KEYS.enter && e.shiftKey === false) {
			e.preventDefault();
			this.onReplySave();
		}
	};

	onGoToCustomerChat = async messageId => {
		let message = await MessagesService.getMessage(messageId);

		if (!message) {
			ToastService.error("Error trying to go to Customer Chat");
			return;
		}

		let url = `${window.location.origin}/inbox?contactId=${message.Contact.public_id}&locationId=${message.Location.public_id}`;
		window.location.href = url;
	};

	renderDate(message, previousMessage) {
		if (previousMessage && !MessagesService.shouldAppendDate(previousMessage.created_at, message.created_at)) {
			return null;
		}

		let date = MessagesService.getRelativeDate(message.created_at);

		return (
			<div key={`${message.id}-date`} className="mb-message-list-divider">
				<div className="mb-message-list-divider-line" />
				<div className="mb-message-list-divider-date">{date}</div>
				<div className="mb-message-list-divider-line" />
			</div>
		);
	}

	renderUnreadDivider(message, previousMessage) {
		if (!TeamChatService.shouldAppendUnreadIndicator(message, previousMessage)) {
			return null;
		}

		return (
			<div key={`${message.id}-date`} className="mb-message-list-divider" ref={ref => (this.newLine = ref)}>
				<div className="mb-message-list-divider-line mb-message-list-divider-line--danger" />
				<div className="mb-message-list-divider-date mb-message-list-divider-date--danger">NEW</div>
				<div className="mb-message-list-divider-line mb-message-list-divider-line--danger" />
			</div>
		);
	}

	renderName(message, previousMessage) {
		// If there is no previous message, then we should not render the name header
		if (!previousMessage) {
			return null;
		}

		let isPreviousEvent = previousMessage && previousMessage.event_type !== "message";
		let isCurrentEvent = message && message.event_type !== "message";

		// If the current and previous messages are both events, we should not render the name header
		if (isCurrentEvent) {
			return null;
		}

		// If the previous message is not an event, and the sender_user_ids on both the current and previous message are the same,
		// it can be assumed that the sender is the same and we should not preppend the name
		if (
			!isPreviousEvent &&
			previousMessage &&
			previousMessage.sender_user_id === message.sender_user_id &&
			moment(message.created_at).diff(moment(previousMessage.created_at), "minutes") < 5
		) {
			return null;
		}

		let date = moment(message.created_at).format("h:mm a");

		return (
			<div key={`${message.id}-name`} className="mb-message-list-name">
				{message.sender_user_name}
				<span className="mb-message-list-date">{date}</span>
			</div>
		);
	}

	renderEdited(message) {
		if (!message.status_events) {
			return null;
		}

		let edited = false;
		let editedTime = null;
		try {
			let events = JSON.parse(message.status_events);
			events.reverse();

			let event = events.find(item => item.event === STATUS_EVENTS.messageEdited.id);
			if (event) {
				edited = true;
				editedTime = moment(event.created_at).format("MMMM Do YYYY, h:mm A");
			}
		} catch (error) {
			console.log(`Error rendering edited `, error);
		}

		if (!edited) {
			return null;
		}

		return (
			<>
				<div className="mb-message-date mb-message-date--edited" data-tip data-for={`rtt-tc-message-edited-${message.id}`}>
					{STATUS_EVENTS.messageEdited.label}
				</div>
				<ReactTooltip id={`rtt-tc-message-edited-${message.id}`} className="mb-react-tooltip" type="info" effect="solid" place="top" arrowColor="#333">
					Edited on: {editedTime}
				</ReactTooltip>
			</>
		);
	}

	onReplyClicked = async messageReplyId => {
		if (this.props.onReplyClicked) {
			await this.props.onReplyClicked(messageReplyId);
		}
	};

	renderReply() {
		let { message, isReplyMedia } = this.state;
		if (!message.reply_id) {
			return null;
		}

		return (
			<div className="mb-message-reply" onClick={() => this.onReplyClicked(message.reply_id)}>
				<div className="mb-message-reply-name">{message.reply_name}</div>
				{message.reply_content && <div className="mb-message-reply-content">{this.renderContent(message.reply_id, message.reply_content)}</div>}
				{isReplyMedia && <div className="mb-message-media-content">{this.renderReplyMedia()}</div>}
			</div>
		);
	}

	renderReplyMedia() {
		let { message, replyMedia } = this.state;

		if (!replyMedia) {
			return null;
		}

		return <ThreadMedia key={message.reply_media_id} media={replyMedia} maxHeightOrWidth={50} idPrefix={"tc-reply"} readOnly={true} />;
	}

	renderMedia() {
		let { media } = this.state;
		let { onMediaClicked, mediaMaxHeightOrWidth, mediaReadOnly, horizontalMedia } = this.props;

		if (!media || media.length === 0) {
			return null;
		}

		if (horizontalMedia) {
			return (
				<div className="mb-message-team-set__media--horizontal">
					{media.map(m => {
						let maxHeightOrWidth = mediaMaxHeightOrWidth;
						// Make files twice the width
						if (maxHeightOrWidth && m.type !== MEDIA_TYPES.image) {
							maxHeightOrWidth = 2 * maxHeightOrWidth;
						}
						return <ThreadMedia key={m.id} media={m} onMediaClicked={onMediaClicked} maxHeightOrWidth={maxHeightOrWidth} readOnly={mediaReadOnly} />;
					})}
				</div>
			);
		}

		return media.map(m => {
			return <ThreadMedia key={m.id} media={m} onMediaClicked={onMediaClicked} maxHeightOrWidth={mediaMaxHeightOrWidth} readOnly={mediaReadOnly} />;
		});
	}

	renderYoutubeVideos(blurb) {
		let { message } = this.state;

		let words = blurb.split(" ");

		return words.map((word, index) => {
			let key = parseInt(`${message.id}${index}`, 10);

			if (UtilityService.isYoutubeLink(word)) {
				// Get the ID of the youtube video
				let youtubeId = UtilityService.getYoutubeId(word);

				// Return the standard youtube iframe for embedded videos
				return (
					<div className="mb-teamchat-youtube-container">
						<iframe
							title="youtube-video"
							key={key}
							className="mb-teamchat-youtube-iframe"
							type="text/html"
							src={`https://www.youtube.com/embed/${youtubeId}`}
							frameborder="0"
							allowfullscreen="true"
						/>
					</div>
				);
			}

			return (
				<Linkify>
					<span key={key}>{word} </span>
				</Linkify>
			);
		});
	}

	renderContent(id, content) {
		return content.split("\n").map((blurb, index) => {
			let key = parseInt(`${id}${index}`, 10);

			if (blurb.length === 0) {
				return <br key={key} />;
			}

			let direction = UtilityService.detectLanguageDirection(blurb);

			return (
				<p className="mb-message-content-blurb" dir={direction} key={key}>
					{UtilityService.hasYoutubeLink(blurb) ? this.renderYoutubeVideos(blurb) : <Linkify>{blurb}</Linkify>}
				</p>
			);
		});
	}

	renderForwardedMessage() {
		let { message } = this.state;
		if (!message) {
			return null;
		}

		if (!message.forward_message_media_id && (!message.forward_message_content || message.forward_message_content.length < 1)) {
			return null;
		}

		let { onMediaClicked } = this.props;

		return (
			<div className="mb-message-team-forward">
				<div className="mb-message-team-forward__message">
					<div className="mb-message-team-forward__message__header">
						<div onClick={() => this.onGoToCustomerChat(message.forward_message_id)}>
							<Icon.CornerUpRight size={14} /> Shared customer message from {message.forward_message_name}
						</div>
					</div>
					{message.forward_media_object && (
						<ThreadMedia key={message.forward_message_media_id} media={message.forward_media_object} onMediaClicked={onMediaClicked} idPrefix="tc-forward" />
					)}
					<div className="mb-message-team-forward__message__text">{this.renderContent(message.forward_message_id, message.forward_message_content)}</div>
				</div>
			</div>
		);
	}

	renderTeamMessage() {
		let { message, isMedia, deleted, isEdit, enableEditSave, editedMessageText, editSaveLoading, isReply } = this.state;
		let { isReference, highlightReference, hideHoverOptions, messagePins, hasAssociatedTask } = this.props;
		let { id, content, reactions } = message;

		let contentStyle = {};
		let isDeleted = deleted || message.status === "deleted";

		// If the content is simply one emoji, then increase the size of the emoji to font size 48
		if (content.length === 2 && UtilityService.isSingleEmoji(content)) {
			contentStyle = {
				fontSize: 48
			};
		}

		let isMessagePinned = messagePins ? messagePins.findIndex(pin => id === pin.message_id) >= 0 : false;
		let date = moment(message.created_at).format("h:mm a");

		let classesApplied = "mb-message-team";

		if (isReference && highlightReference) {
			classesApplied += " mb-message-team--reference";
		}

		if (hideHoverOptions) {
			classesApplied += " mb-message-team--hide-options";
		}

		if (hasAssociatedTask) {
			classesApplied += " mb-message-team--task-incomplete";
		}

		return (
			<div className={classesApplied} ref={ref => (this.messageRef = ref)}>
				{isEdit && (
					<div className="mb-message-edit" onScroll={this.onEditInputScroll}>
						<textarea
							id="mb-message-edit-input"
							ref={ref => (this.editMessageInput = ref)}
							className="mb-message-edit-input"
							placeholder="Edit your message ..."
							value={editedMessageText}
							onChange={this.onEditMessageChange}
							onKeyDown={this.onKeyDown}
						/>
						<div className="mb-message-edit__actions">
							<div
								id="mb-message-edit-button--success"
								onClick={this.onEditSave}
								className={`mb-message-edit-button mb-message-edit-button--success ${enableEditSave ? "" : "mb-message-edit-button--disabled"}`}
							>
								{editSaveLoading ? "Saving ..." : "Save"}
							</div>
							<div onClick={this.onEditCancel} className="mb-message-edit-button mb-message-edit-button--cancel">
								Cancel
							</div>
						</div>
					</div>
				)}

				{!isEdit && !isDeleted && (
					<>
						<div className="mb-message-team-set">
							{isReply && this.renderReply()}
							{isMedia && this.renderMedia()}
							<div className="mb-message-team-content" style={contentStyle}>
								{content && this.renderContent(id, content)}
								<Reactions ref={ref => (this.reactionsComponent = ref)} messageId={id} reactions={reactions} />
							</div>
						</div>
						{!hideHoverOptions && (
							<>
								<div className="mb-message-date">{date}</div>
								{this.renderEdited(message)}
								<div ref={ref => (this.reactionButton = ref)} id="mb-message-reaction" className="mb-message-reaction" onClick={this.onReactionClick}>
									<Icon.Smile size="20" />
								</div>
								<div id="mb-message-team-context" className="mb-message-team-context" onClick={this.onContextClick}>
									<Icon.MoreVertical size="20" />
								</div>
							</>
						)}
					</>
				)}
				{isDeleted && this.renderDeletedMessage()}
				{isMessagePinned ? <Pin color="#333" size={20} /> : null}
				{this.renderContextMenu(isMessagePinned)}
			</div>
		);
	}

	renderContextMenu(isMessagePinned) {
		let user = UserService.get();
		let { isContext, message } = this.state;
		let { sender_user_id, send_after } = message;

		let sendAfter = moment(send_after);
		let now = moment();
		let isSendingLater = now < sendAfter;

		let allowTaskCreation = user.GroupPermission.create_tasks;

		if (!isContext) {
			return null;
		}

		let modifier = "mb-message-context-menu--team";

		return (
			<div ref={ref => (this.context = ref)} className={`mb-message-context-menu ${modifier}`}>
				<div className="mb-message-context-menu-item" onClick={this.onMarkAsUnread}>
					Mark as Unread
				</div>
				<div className="mb-message-context-menu-item" onClick={this.onReplyToMessage}>
					Reply
				</div>
				{sender_user_id === user.id && (
					<div className="mb-message-context-menu-item" id="mb-message-context-menu-update" onClick={this.onEditMessage}>
						Edit Message
					</div>
				)}
				{sender_user_id === user.id && user.GroupPermission.delete_teamchat_messages && (
					<div className="mb-message-context-menu-item" id="mb-message-context-menu-delete" onClick={this.onDeleteMessageClick}>
						{isSendingLater ? "Cancel Message" : "Delete Message"}
					</div>
				)}
				{allowTaskCreation && (
					<div className="mb-message-context-menu-item" id="mb-message-context-menu-create-task" onClick={this.onCreateTaskMessageClick}>
						Create Task
					</div>
				)}
				<div className="mb-message-context-menu-item" onClick={this.onPinMessage}>
					{isMessagePinned ? "Unpin Message" : "Pin Message"}
				</div>
			</div>
		);
	}

	renderDeletedMessage() {
		let { message } = this.state;
		let { direction } = message;

		return (
			<div className={`mb-message-box ${direction === DIRECTION.out ? "mb-message-box--out" : ""} mb-message-box--removed`}>This message has been deleted.</div>
		);
	}

	render() {
		let { message, previousMessage } = this.state;
		let { hideDate } = this.props;

		let isEvent = message.event_type !== "message";

		if (isEvent) {
			return (
				<React.Fragment>
					{!hideDate && this.renderDate(message, previousMessage)}
					<MessageEvent message={message} />
				</React.Fragment>
			);
		}

		return (
			<React.Fragment>
				{!hideDate && this.renderDate(message, previousMessage)}
				{this.renderUnreadDivider(message, previousMessage)}
				{this.renderName(message, previousMessage)}
				{this.renderForwardedMessage()}
				{this.renderTeamMessage()}
			</React.Fragment>
		);
	}
}

export default TeamChatMessage;
