import _ from "lodash";

import AdminTemplates from "../scenes/Templates/AdminTemplates";
import Connections from "../scenes/Locations/Connections";
import TextCommands from "../scenes/Workflows/Keywords/Keywords";
import WidgetConfig from "../scenes/Locations/WidgetConfig/WidgetConfig";
import LocationFeatures from "../scenes/Locations/LocationFeatures";
import CRMIntegrations from "../scenes/CRMIntegrations/CRMIntegrations";
import APIKeys from "../scenes/Locations/APIKeys";
import General from "../scenes/Settings/General";
import Reviews from "../scenes/Settings/Reviews/Reviews";
import Hours from "../scenes/Settings/Hours";
import Chatbots from "../scenes/Settings/Chatbots";
import WorkflowOverview from "../scenes/Settings/Workflows/WorkflowOverview";
import AutoReplies from "../scenes/Settings/AutoReplies";
import Numbers from "../scenes/Settings/Numbers/Numbers";
import GroupPermissions from "../scenes/Settings/GroupPermissions/GroupPermissions";
import Firewall from "../scenes/Settings/Firewall/Firewall";
import Reengagements from "../scenes/Settings/Reengagements/Reengagements";
import GBMConnection from "../scenes/Locations/Gbm/GBMConnection";
import ShortLinks from "../scenes/Settings/ShortLinks/ShortLinks";
import A2P from "../scenes/Settings/A2P/A2P";

import GROUP_PERMISSIONS from "../constants/GroupPermissions";

import WorkflowService from "../services/WorkflowService";
import LocationService from "../services/LocationService";
import UserService from "../services/UserService";
import ReengagementService from "../services/ReengagementService";
import UsersOverview from "../scenes/Users/UsersOverview";
import Locations from "../scenes/Locations/Locations";

export const INBOX_ROUTES = {
	templates: {
		name: "Templates",
		route: "/settings/templates",
		component: AdminTemplates,
		enabled: () => UserService.get().GroupPermission.view_templates
	},
	hours: {
		name: "Hours",
		route: "/settings/hours",
		component: Hours,
		enabled: () => UserService.get().GroupPermission.view_business_hours
	},
	keywords: {
		name: "Keywords",
		route: "/settings/keywords",
		component: TextCommands,
		enabled: () => WorkflowService.isKeywordsEnabled()
	},
	autoReplies: {
		name: "Auto Replies",
		route: "/settings/auto-replies",
		component: AutoReplies,
		enabled: () => WorkflowService.isAutoRepliesEnabled()
	},
	chatbots: {
		name: "Chatbots",
		route: "/settings/chatbots",
		component: Chatbots,
		enabled: () => LocationService.isLocationFeaturePermissible("chatbots") && UserService.get().GroupPermission.view_chatbots
	}
};

export const MORE_ROUTES = {
	workflows: {
		name: "Workflows",
		route: "/settings/workflows",
		component: WorkflowOverview,
		enabled: () => WorkflowService.canViewWorkflows()
	},
	firewall: {
		name: "Firewall",
		route: "/settings/firewall",
		component: Firewall,
		enabled: () =>
			UserService.get().GroupPermission.view_global_firewall ||
			UserService.get().GroupPermission.view_company_firewall ||
			UserService.get().GroupPermission.view_location_firewall
	},
	gbmConnection: {
		name: "gbm",
		route: "/settings/connections/gbm",
		component: GBMConnection,
		enabled: () => UserService.get().GroupPermission.view_locations,
		hideFromMenu: true
	},
	reengagements: {
		name: "Reengagements",
		route: "/settings/reengagements",
		component: Reengagements,
		enabled: () => ReengagementService.canViewReenagements()
	},
	shortLinks: {
		name: "Short Links",
		route: "/settings/short-links",
		component: ShortLinks,
		enabled: () => UserService.isSuperAdminOrCustomerSuccessOrAccountOwner()
	}
};

export const GENERAL_ROUTES = {
	general: {
		name: "General",
		route: "/settings",
		component: General,
		enabled: () => true
	},
	connections: {
		name: "Connections",
		route: "/settings/connections",
		component: Connections,
		enabled: () => UserService.get().GroupPermission.view_locations
	},
	reviews: {
		name: "Reviews",
		route: "/settings/reviews",
		component: Reviews,
		enabled: () => UserService.get().GroupPermission.view_reviews
	},
	widgets: {
		name: "Widgets",
		route: "/settings/widgets",
		component: WidgetConfig,
		enabled: () => UserService.get().GroupPermission.view_widgets
	},
	users: {
		name: "Users",
		route: "/settings/users",
		component: UsersOverview,
		enabled: () => UserService.get().GroupPermission.view_team
	},
	locations: {
		name: "Locations",
		route: "/settings/locations",
		component: Locations,
		enabled: () => UserService.isSuperAdminOrCustomerSuccessOrReseller()
	}
};

export const CHATBOT_TABS = {
	configuration: {
		id: "configuration",
		value: "Configuration"
	},
	faq: {
		id: "faq",
		value: "Faq"
	}
};

export const HOUR_TABS = {
	regular: {
		id: "regular",
		value: "Regular"
	},
	holiday: {
		id: "holiday",
		value: "Holiday"
	},
	messaging: {
		id: "messaging",
		value: "Messaging"
	},
	reengagements: {
		id: "reengagements",
		value: "Reengagements"
	}
};

export const REVIEW_TABS = {
	general: {
		id: "general",
		value: "General"
	},
	reviewInviteQueue: {
		id: "reviewInviteQueue",
		value: "Invite Queue"
	},
	reviewSites: {
		id: "reviewSites",
		value: "Review Sites"
	}
};

export const NUMBERS_TABS = {
	twilioA2P: {
		id: "twilioA2P",
		value: "Twilio A2P"
	}
};

export const FIREWALL_TYPE = {
	phone: {
		id: "phone",
		value: "Phone"
	},
	email: {
		id: "email",
		value: "Email"
	}
};

export const FIREWALL_MATCH_TYPE = {
	exact: {
		id: "exact",
		value: "Exact Match"
	},
	loose: {
		id: "loose",
		value: "Loose Match"
	}
};

export const FIREWALL_FILTERS = {
	global: {
		id: "global",
		display: "Global",
		order: 1
	},
	location: {
		id: "location",
		display: "Location",
		order: 2
	},
	company: {
		id: "company",
		display: "Company",
		order: 3
	}
};

export const FIREWALL_COLUMNS = {
	name: {
		id: "name",
		value: "Name",
		sortable: true,
		sortField: "name",
		width: 1
	},
	description: {
		id: "description",
		value: "Description",
		sortable: true,
		sortField: "description",
		width: 1
	},
	value: {
		id: "value",
		value: "value",
		sortable: true,
		sortField: "value",
		width: 1
	},
	field: {
		id: "field",
		value: "Field",
		sortable: true,
		sortField: "field",
		width: 1
	},
	match_type: {
		id: "match_type",
		value: "Match Type",
		sortable: true,
		sortField: "match_type",
		width: 1
	},
	actions: {
		id: "actions",
		value: "Actions",
		sortable: false,
		sortField: "actions",
		width: 1
	}
};

export const A2P_TABS = {
	brands: {
		id: "brands",
		value: "Brands"
	},
	campaigns: {
		id: "campaigns",
		value: "Campaigns"
	},
	submissions: {
		id: "submissions",
		value: "Submissions"
	},
	webhooks: {
		id: "webhooks",
		value: "Webhooks"
	},
	numberAssociations: {
		id: "numberAssociations",
		value: "Number Associations"
	}
};

export const REENGAGEMENTS_COLUMNS = {
	name: {
		id: "name",
		value: "Name",
		sortable: true,
		sortField: "name",
		width: 2
	},
	appointment_look_back_days: {
		id: "appointment_look_back_days",
		value: "Appt Look Back Days",
		sortable: true,
		sortField: "appointment_look_back_days",
		width: 1,
		tooltip: "Appointment Look Back Days. The amount of days to look back at all existing appointments for a location."
	},
	contact_last_appointment_days: {
		id: "contact_last_appointment_days",
		value: "Last Appt Days",
		sortable: true,
		sortField: "contact_last_appointment_days",
		width: 1,
		tooltip: "Contact Last Appointment Days. The amount of days to look back to see if the most recent appointment booking data is more than X days ago."
	},
	last_eligible_reengagements: {
		id: "last_eligible_reengagements",
		value: "ERs",
		sortable: true,
		sortField: "last_eligible_reengagements",
		tooltip: "ERs - Eligible Reengagements. The number of eligible contacts the last time that reengagement eligibility was checked",
		width: 1
	},
	actions: {
		id: "actions",
		value: "Actions",
		sortable: false,
		sortField: "actions",
		width: 2
	}
};
