import React, { Component } from "react";
import * as Icon from "react-feather";
import ContentLoader from "react-content-loader";
import Highlighter from "react-highlight-words";
import { PoseGroup } from "react-pose";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import config from "../../config/app/web-app.config";

import SearchInput from "../../components/common/SearchInput";

import UserService from "../../services/UserService";
import CompanyService from "../../services/CompanyService";
import NotificationService from "../../services/NotificationService";
import UtilityService from "../../services/UtilityService";

import { ContextMenu, KEYS } from "../../constants/Messenger";

import "./company-selector.css";
import Action from "../../components/common/Action";

class CompanySelector extends Component {
	constructor(props) {
		super(props);
		this.state = {
			searchedCompanies: [],
			recentlyUsedCompanies: [],
			searchTerm: "",
			selectedIndex: 0,
			showList: false,
			loading: false
		};

		this.searchInput = null;
		this.currentSelectedCompany = null;
		this.companySelectorModal = null;
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	async componentDidMount() {
		document.addEventListener("mousedown", this.onHandleClick, false);

		NotificationService.subscribeOnce("companyChanged", "companySelector_component", () => {
			this.resetComponent();
		});

		await this.resetComponent();
	}

	componentWillUnmount() {
		document.removeEventListener("mousedown", this.onHandleClick, false);
	}

	async resetComponent() {
		await this.update({
			searchedCompanies: [],
			recentlyUsedCompanies: [],
			searchTerm: "",
			selectedIndex: 0,
			showList: false
		});

		await this.getRecentlyUsedCompanies();
	}

	getRecentlyUsedCompanies = async () => {
		let recentlyUsedCompanies = await CompanyService.getRecentlyUsedCompanies();
		recentlyUsedCompanies = recentlyUsedCompanies.reverse();

		await this.update({ recentlyUsedCompanies });
	};

	onSearchChange = async value => {
		if (!value) {
			await this.update({ searchedCompanies: [], selectedIndex: 0, searchTerm: "" });
			return;
		}

		let query = { searchTerm: value, limit: 30, isAdvanceFetch: true, shouldSearchLocationName: true, shouldSearchLocationAddress: true };

		// If we're searching a number, it's a locationID or companyID
		if (/^-?\d+$/.test(value)) {
			query.locationId = value;
			query.companyId = value;
		}

		let searchedCompanies = await CompanyService.fetchCompanies(query);

		// Only companies with locations already created are allowed to be selected
		if (searchedCompanies) {
			searchedCompanies = searchedCompanies.filter(company => company.locations && company.locations.length > 0);
		}

		await this.update({ searchedCompanies, selectedIndex: 0, searchTerm: value });
	};

	onEnter = async e => {
		if (e.keyCode === KEYS.up || e.keyCode === KEYS.down || e.keyCode === KEYS.enter || e.keyCode === KEYS.tab) {
			e.preventDefault();
			await this.triggerKeyboardEvent(e.keyCode);
		}
	};

	triggerKeyboardEvent = async keyCode => {
		let { selectedIndex, searchedCompanies } = this.state;

		if (keyCode === KEYS.enter || keyCode === KEYS.tab) {
			let company = searchedCompanies[selectedIndex];
			if (company) {
				let companyName = company.name;
				let companyId = company.id;
				await this.onSelect(companyId, companyName);
			}
			return;
		}

		if (keyCode === KEYS.down) {
			selectedIndex++;
		} else if (keyCode === KEYS.up) {
			selectedIndex--;
		}

		if (selectedIndex < 0) {
			selectedIndex = searchedCompanies.length - 1;
		} else if (selectedIndex > searchedCompanies.length - 1) {
			selectedIndex = 0;
		}

		await this.update({
			selectedIndex
		});

		if (this.currentSelectedCompany) {
			this.currentSelectedCompany.scrollIntoView(false);
		}
	};

	async onSelect({ companyId, companyName, locationId }) {
		if (!companyId || !companyName) {
			return;
		}

		await this.update({ loading: true });

		// Register the company and get the new list of recent companies
		CompanyService.registerRecentlyUsedCompany(companyId, companyName);

		await this.getRecentlyUsedCompanies();

		await CompanyService.setCompanyAndLocationAsAdmin({ companyId, companyName, locationId });

		await this.update({ loading: false });
		await this.resetComponent();
	}

	goToDemandHub = () => {
		this.onSelect({ companyId: config.DEMANDHUB.COMPANY.ID, companyName: config.DEMANDHUB.COMPANY.NAME });
	};

	goToSettingsPage = () => {
		this.props.history.push("/settings");
		this.resetComponent();
	};

	goToCustomerSuccessPage = () => {
		this.props.history.push("/customer-success");
		this.resetComponent();
	};

	goToMessengerPage = () => {
		this.props.history.push("/inbox");
		this.resetComponent();
	};

	goToCompanyList = () => {
		this.props.history.push("/companies");
		this.resetComponent();
	};

	onCatClicked = () => {
		this.goToDemandHub();
	};

	clearRecentlyUsed = async () => {
		CompanyService.clearRecentlyUsedCompanies();
		await this.getRecentlyUsedCompanies();
	};

	onHandleClick = async e => {
		let { showList } = this.state;

		if (this.companySelectorModal && this.companySelectorModal.contains(e.target)) {
			return;
		}

		if (this.contextContainer && this.contextContainer.contains && this.contextContainer.contains(e.target) && !showList) {
			await this.showModal();
		} else if (this.companySelectorModal && this.companySelectorModal.contains) {
			await this.hideModal();
		}
	};

	showModal = async () => {
		await this.update({ showList: true });
		if (this.searchInput) {
			this.searchInput.focus();
		}
	};

	hideModal = async () => {
		await this.update({ showList: false, searchTerm: "", selectedIndex: 0 });
	};

	renderRecentCompanyListItem(company, index) {
		let { t } = this.props;

		let companyId = company.id;
		let companyName = company.name;

		let tipColour = UtilityService.stringToColour(companyName + companyId);

		return (
			<div key={companyId} className={`company-selector__modal__list__item`}>
				<div className="company-selector__modal__list__item__content" onClick={() => this.onSelect({ companyId, companyName })}>
					<div className={`company-selector__modal__list__item__content__top`}>
						<div className="company-selector__modal__list__item__content__name__text">{companyName}</div>
						<div className="company-selector__modal__list__item__content__colour" style={{ backgroundColor: tipColour }} />
					</div>
					<div className={`company-selector__modal__list__item__content__bottom`}>
						<div className="company-selector__modal__list__item__content__text">
							{t("Company ID")}: {companyId}
						</div>
					</div>
				</div>
			</div>
		);
	}

	renderSearchedCompanyListItem(company, index) {
		let { selectedIndex, searchTerm } = this.state;
		let { t } = this.props;

		let companyId = company.id;
		let companyName = company.name;

		let isCurrentCompanySelected = selectedIndex === index;
		let tipColour = UtilityService.stringToColour(companyName + companyId);
		let companyIdLabel = `${t("Company ID")}: ${companyId}`;

		let isSearchTermNumber = /^-?\d+$/.test(searchTerm);
		let locations = company.locations;

		// If it's a number then we will do some more checks
		let locationMatches = [];
		for (let i = 0; i < locations.length; i++) {
			let location = locations[i];
			let locationId = location.id;
			let locationName = location.name;

			// If our ID includes the search term
			if (isSearchTermNumber && locationId.toString().includes(searchTerm)) {
				locationMatches.push({ type: "id", location });
			} else if (locationName.toLowerCase().includes(searchTerm.toLowerCase())) {
				locationMatches.push({ type: "location", location });
			} else {
				locationMatches.push({ type: "none", location });
			}
		}

		// Decide if we should show the location matches
		let showLocationMatches = true;

		// Case 1: No location matches
		if (locationMatches.length == 0) {
			showLocationMatches = false;
		}
		// Case 2: A single location match
		else if (locationMatches.length == 1) {
			// If that one location is location name match and the search term is already in the company name
			if (locationMatches[0].type == "location" && companyName.toLowerCase().includes(searchTerm.toLowerCase())) {
				showLocationMatches = false;
			}
		}

		return (
			<div
				ref={ref => {
					if (isCurrentCompanySelected) {
						this.currentSelectedCompany = ref;
					}
				}}
				key={companyId}
				className={`company-selector__modal__list__item ${isCurrentCompanySelected && searchTerm ? "company-selector__modal__list__item--selected" : ""}`}
			>
				<div className="company-selector__modal__list__item__content" onClick={() => this.onSelect({ companyId, companyName })}>
					<div className={`company-selector__modal__list__item__content__top`}>
						<div className="company-selector__modal__list__item__content__name__text">{companyName}</div>
						<div className="company-selector__modal__list__item__content__colour" style={{ backgroundColor: tipColour }} />
					</div>
					<div className={`company-selector__modal__list__item__content__bottom`}>
						<div className="company-selector__modal__list__item__content__text">
							<Highlighter searchWords={[searchTerm]} autoEscape={true} textToHighlight={companyIdLabel} />
						</div>
						{showLocationMatches && (
							<div className="company-selector__modal__list__item__content__locations">
								{locationMatches.map((locationPair, index) => {
									let location = locationPair.location;
									let locationId = location.id;
									let locationName = location.name;

									// The blurb to add to the highlighter
									let locationBlurb = `${locationName} (${locationId})`;

									// What should we highlight
									let fragmentToHighlight = searchTerm;

									return (
										<span
											key={locationId}
											className="company-selector__modal__list__item__content__locations__item"
											onClick={event => {
												event.stopPropagation();
												this.onSelect({ companyId, companyName, locationId });
											}}
										>
											<Highlighter searchWords={[fragmentToHighlight]} autoEscape={true} textToHighlight={locationBlurb} />
										</span>
									);
								})}
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}

	renderActions() {
		let { searchTerm } = this.state;

		let classess = "company-selector__modal__list__header__action-list";
		if (searchTerm) {
			classess += " company-selector__modal__list__header__action-list--fade";
		}

		return (
			<div className={classess}>
				<Action id="home" label={`Home`} onClick={this.goToDemandHub} icon={Icon.Home} transparent={true} outline={true} disabled={false} />
				<Action
					id="company-inbox"
					label={`Messeger`}
					onClick={this.goToMessengerPage}
					icon={Icon.MessageSquare}
					transparent={true}
					outline={true}
					disabled={false}
				/>
				<Action
					id="company-cs"
					label={`Customer Success`}
					onClick={this.goToCustomerSuccessPage}
					icon={Icon.UserCheck}
					transparent={true}
					outline={true}
					disabled={false}
				/>
				<Action
					id="company-settings"
					label={`Settings`}
					onClick={this.goToSettingsPage}
					icon={Icon.Settings}
					transparent={true}
					outline={true}
					disabled={false}
				/>
				<Action id="company-list" label={`Companies`} onClick={this.goToCompanyList} icon={Icon.List} transparent={true} outline={true} disabled={false} />
				<Action
					id="clear-recently-used"
					label={`Clear`}
					onClick={this.clearRecentlyUsed}
					icon={Icon.Trash2}
					transparent={true}
					outline={true}
					disabled={false}
				/>
			</div>
		);
	}

	renderRecentList() {
		let { recentlyUsedCompanies } = this.state;
		let { t } = this.props;

		let hasRecentCompanies = recentlyUsedCompanies.length > 0;

		return (
			<>
				{hasRecentCompanies && (
					<>
						<div className="company-selector__modal__list__header">{t("RECENTLY USED")}</div>
						{recentlyUsedCompanies.map((company, index) => this.renderRecentCompanyListItem(company, index))}
					</>
				)}
				{!hasRecentCompanies && (
					<img
						className="company-selector__modal__list__image__placeholder"
						src="https://cdn.demandhub.co/web-app/assets/cat.svg"
						alt="Satbir Was here"
						onClick={this.onCatClicked}
					/>
				)}
			</>
		);
	}

	renderSearchedCompanyList() {
		let { searchedCompanies } = this.state;

		if (searchedCompanies.length < 1) {
			return null;
		}

		return searchedCompanies.map((company, index) => this.renderSearchedCompanyListItem(company, index));
	}

	renderCompanies() {
		let { loading, searchTerm } = this.state;

		let renderSearchResults = searchTerm ? true : false;

		return (
			<div className="company-selector__modal__list">
				{loading && this.renderLoading()}
				{!loading && this.renderActions()}
				{!loading && !renderSearchResults && this.renderRecentList()}
				{!loading && renderSearchResults && this.renderSearchedCompanyList()}
			</div>
		);
	}

	renderLoading() {
		return (
			<div className="company-selector__modal__loading">
				<ContentLoader height={480} width={"100%"}>
					{/* The rows */}
					<rect x="0" y="40" rx="5" ry="5" width="100%" height="60" />
					<rect x="0" y="105" rx="5" ry="5" width="100%" height="60" />
					<rect x="0" y="170" rx="5" ry="5" width="100%" height="60" />
					<rect x="0" y="235" rx="5" ry="5" width="100%" height="60" />
					<rect x="0" y="300" rx="5" ry="5" width="100%" height="60" />
					<rect x="0" y="365" rx="5" ry="5" width="100%" height="60" />
					<rect x="0" y="430" rx="5" ry="5" width="100%" height="60" />
				</ContentLoader>
			</div>
		);
	}

	renderModal() {
		let { t } = this.props;

		return (
			<ContextMenu key="container" className="company-selector__modal" ref={ref => (this.companySelectorModal = ref)}>
				<div className="company-selector__modal__search">
					<SearchInput
						id="company-selector__search_input"
						ref={input => {
							this.searchInput = input;
						}}
						placeholder={t("Search by company name...")}
						onChange={this.onSearchChange}
						onKeyDown={this.onEnter}
						debounce={false}
					/>
				</div>
				{this.renderCompanies()}
			</ContextMenu>
		);
	}

	render() {
		let { showList } = this.state;

		let company = UserService.getActiveCompany();

		let companyId = company.id;
		let companyName = company.name;
		let isSuperAdminOrCustomerSuccess = UserService.isSuperAdminOrCustomerSuccess();

		if (!companyId) {
			return;
		}

		return (
			<div ref={ref => (this.contextContainer = ref)} className="company-selector">
				<div className="company-selector__icon">
					<Icon.Briefcase size="16" />
				</div>
				<div className="company-selector__name" onClick={this.showModal}>
					{isSuperAdminOrCustomerSuccess ? `(${companyId}) ` : ""}
					{companyName}
				</div>
				<PoseGroup>{showList && this.renderModal()}</PoseGroup>
			</div>
		);
	}
}

export default withTranslation(null, { withRef: true })(withRouter(CompanySelector));
