import React from "react";
import ContentLoader from "react-content-loader";
import Select from "react-select";

import CrmIntegrationsService from "../../services/CrmIntegrationsService";
import ToastService from "../../services/ToastService";
import UserService from "../../services/UserService";

import Input from "../../components/common/Input";
import Modal from "../../components/common/DHModal";
import Alert from "../../components/common/Alert";

import { STATUS } from "../../constants/CommonConstants";
import { CRM_INTEGRATIONS } from "../../constants/CRMIntegration";

class ConnectOdOfficeProModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			testingConnection: false,

			selectedIntegration: null,
			selectableIntegrations: [],
			integration: null,
			locations: [],

			urlEndpoint: "",
			apiKey: "",
			code: null,

			// alerts
			showAlertFailedToAuthorize: false,
			showAlertIntegrationNotFound: false,
			showAlertTestFailed: false,
			showAlertConnectionComplete: false,
			showDisconnectModal: false
		};
	}

	componentDidMount = async () => {
		await this.resetComponent();
	};

	componentDidUpdate(prevProps) {
		let { show } = this.props;

		if (prevProps.show !== show) {
			this.resetComponent();
		}
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	resetComponent = async () => {
		await this.update({
			loading: false,
			integration: null,
			code: null,
			locations: [],

			urlEndpoint: "",
			apiKey: ""
		});

		let { show } = this.props;

		// If we are not showing the dialog, don't fetch anything
		if (!show) {
			return;
		}

		let { integrationId } = this.props;

		if (!integrationId) {
			return;
		}

		await this.fetchIntegrations();
		await this.onIntegrationSelected();
	};

	fetchIntegrations = async () => {
		let integrations = await CrmIntegrationsService.fetch({
			locationId: UserService.getActiveLocation().id,
			params: {
				type: CRM_INTEGRATIONS.type.odofficepro.name,
				status: [STATUS.active]
			},
			ignoreCache: true
		});

		if (!integrations) {
			this.update({
				selectableIntegrations: []
			});
			return;
		}

		let selectableIntegrations = integrations.map(i => {
			return { value: i.id, label: i.name ? i.name : CRM_INTEGRATIONS[i.crm_type].displayName };
		});

		this.update({
			selectableIntegrations
		});
	};

	onIntegrationSelected = async integrationOption => {
		let integrationId = null;

		if (integrationOption) {
			integrationId = integrationOption.value;
		}

		if (!integrationId) {
			// Default to the passed in integration id
			integrationId = this.props.integrationId;
		}

		await this.update({ loading: true });
		try {
			let integration = await CrmIntegrationsService.fetchRestIntegration(integrationId);

			if (!integration || integration.status === STATUS.deleted) {
				// Show integration not found dialog
				await this.update({
					loading: false,
					showAlertIntegrationNotFound: true
				});

				return;
			}

			let urlEndpoint = "";
			let apiKey = "";
			if (integration && integration.meta_data && integration.meta_data.urlEndpoint && integration.meta_data.apiKey) {
				urlEndpoint = integration.meta_data.urlEndpoint;
				apiKey = integration.meta_data.apiKey;
			}

			await this.update({
				integration,
				urlEndpoint,
				apiKey,
				selectedIntegration: { value: integration.id, label: integration.name ? integration.name : CRM_INTEGRATIONS[integration.crm_type].displayName }
			});
		} catch (error) {
			console.log(error);
		}
		await this.update({ loading: false });
	};

	onCancel = async event => {
		let { onHide } = this.props;
		let { loading } = this.state;

		if (loading) {
			return;
		}

		await this.update({ showLocationSelector: false });

		if (onHide) {
			onHide();
		}
	};

	onTestConnection = async () => {
		let { loading } = this.state;

		if (loading) {
			return;
		}

		await this.update({ loading: true, testingConnection: true });

		let testResponse = await this.testConnection();

		if (testResponse.status) {
			ToastService.info(`Connection Successful!`);
			await this.update({ showAlertTestFailed: false, showAlertConnectionComplete: true });
		} else {
			ToastService.info("Connection Failed.");
			await this.update({ showAlertTestFailed: true });
		}

		await this.update({ loading: false, testingConnection: false, lastTestStatus: testResponse.status });
	};

	onClickDisconnect = () => {
		this.update({ showDisconnectModal: true });
	};

	disconnectIntegration = async confirm => {
		if (!confirm) {
			await this.update({ showDisconnectModal: false });
			return;
		}

		if (this.props.disconnectIntegration) {
			this.props.disconnectIntegration();
		}

		await this.update({ showDisconnectModal: false });
	};

	testConnection = async code => {
		let { selectedIntegration } = this.state;

		if (!selectedIntegration) {
			return;
		}

		let { urlEndpoint, apiKey } = this.state;

		if (!urlEndpoint || !apiKey) {
			ToastService.info("Cannot authorize. Client Id and Secret required.");
			return;
		}

		let data = {
			integrationId: selectedIntegration.value,
			login: urlEndpoint,
			password: apiKey
		};

		if (code) {
			data.code = code;
		}

		let testResponse = await CrmIntegrationsService.testRestIntegration(data);
		return testResponse;
	};

	onInputChange = async event => {
		await this.update({ [event.target.name]: event.target.value });
	};

	onConfirmConnectionComplete = async event => {
		let { onHide } = this.props;

		// If we get here, we're all done
		await this.update({ loading: false, showAlertConnectionComplete: false });

		window.history.replaceState({}, document.title, "/settings/connections");

		if (onHide) {
			onHide();
		}
	};

	renderTestConnectionButton = () => {
		let { urlEndpoint, apiKey } = this.state;

		let isDisabled = true;

		if (urlEndpoint && urlEndpoint.length > 0 && apiKey && apiKey.length > 0) {
			isDisabled = false;
		}

		return (
			<div className={`mb-button ${isDisabled ? "mb-button--disabled" : ""}`} onClick={this.onTestConnection}>
				Test Connection
			</div>
		);
	};

	renderAlerts = () => {
		let { showAlertFailedToAuthorize, showAlertIntegrationNotFound, showAlertTestFailed, showAlertConnectionComplete, showDisconnectModal } = this.state;

		return (
			<>
				<Alert
					type="error"
					show={showAlertIntegrationNotFound}
					title="Not Found"
					confirm="OK"
					onClose={() => this.update({ showAlertIntegrationNotFound: false })}
				>
					The OD Office Pro integration was not found.
				</Alert>
				<Alert
					type="error"
					show={showAlertFailedToAuthorize}
					title="Failed To Authorize"
					confirm="OK"
					onClose={() => this.update({ showAlertFailedToAuthorize: false })}
				>
					Unable to authorize. Please verify credentials and try again.
				</Alert>
				<Alert type="error" show={showAlertTestFailed} title="Connection Test Failed" confirm="OK" onClose={() => this.update({ showAlertTestFailed: false })}>
					The connection failed. Please verify credentials and try again.
				</Alert>
				<Alert type="success" show={showAlertConnectionComplete} title="Connection Complete" confirm="OK" onClose={this.onConfirmConnectionComplete}>
					The OD Office Pro connection is all set up.
				</Alert>
				<Alert type="info" show={showDisconnectModal} title={"Disconnect"} confirm={"Yes"} cancel={"No"} onClose={this.disconnectIntegration}>
					<div>{`Are you sure you would like to disconnect the integration?`}</div>
				</Alert>
			</>
		);
	};

	renderForm = () => {
		let {
			urlEndpoint,
			apiKey,
			loading,

			selectedIntegration,
			selectableIntegrations
		} = this.state;

		if (loading) {
			return null;
		}

		return (
			<>
				{selectableIntegrations.length > 1 && selectedIntegration && (
					<div className="Connections-modal__select">
						<div>Integration</div>
						<Select
							id={`selectable-integrations`}
							options={selectableIntegrations}
							value={selectedIntegration}
							onChange={this.onIntegrationSelected}
							placeholder={`${CRM_INTEGRATIONS.type.odofficepro.displayName} Integration`}
						/>
					</div>
				)}
				<div className="Connections-modal__input">
					<Input label="URL Endpoint" name="urlEndpoint" id="url-endpoint" type="text" onChange={this.onInputChange} value={urlEndpoint} required />
				</div>
				<div className="Connections-modal__input">
					<Input label="Api Key" name="apiKey" id="api-key" type="text" onChange={this.onInputChange} value={apiKey} required />
				</div>
				<div className="modal__actions">
					<button id="od-office-pro-cancel" className="mb-button mb-button--cancel" onClick={this.onCancel}>
						Cancel
					</button>
					<button
						id="od-office-pro-disconnect"
						className={`mb-button mb-button--cancel ${this.state.loading ? "mb-button--disabled" : ""}`}
						disabled={this.state.loading}
						onClick={this.onClickDisconnect}
					>
						Disconnect
					</button>
					{this.renderTestConnectionButton()}
				</div>
			</>
		);
	};

	renderLoading = () => {
		let { loading, testingConnection } = this.state;

		if (!loading) {
			return null;
		}

		if (testingConnection) {
			return (
				<div className="modal__flex-container">
					<h2 className="text-center">Testing Connection...</h2>
					<ContentLoader height={160} width={"100%"}>
						<rect x="0" y="0" rx="5" ry="5" width="100%" height="40" />
						<rect x="0" y="50" rx="5" ry="5" width="100%" height="40" />
						<rect x="0" y="100" rx="5" ry="5" width="100%" height="40" />
					</ContentLoader>
				</div>
			);
		}

		return (
			<div className="modal__flex-container">
				<h2 className="text-center">Loading...</h2>
				<ContentLoader height={160} width={"100%"}>
					<rect x="0" y="0" rx="5" ry="5" width="100%" height="40" />
					<rect x="0" y="50" rx="5" ry="5" width="100%" height="40" />
					<rect x="0" y="100" rx="5" ry="5" width="100%" height="40" />
				</ContentLoader>
			</div>
		);
	};

	render = () => {
		let { show } = this.props;
		return (
			<Modal title="Connect to OD Office Pro" show={show} onHide={this.onCancel}>
				{this.renderForm()}
				{this.renderLoading()}
				{this.renderAlerts()}
			</Modal>
		);
	};
}

export default ConnectOdOfficeProModal;
