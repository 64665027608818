import Kichiri from "./KichiriService";
import UserService from "./UserService";
import { BULK_TYPES } from "../constants/BulkActionsConstants";

const BulkSendService = {
	/**
	 * Send invites in bulk
	 * @param {Array} contacts An array of objects containing the id and name of the contact
	 * @param {*} templateId
	 * @returns {Object}
	 */
	async bulkSendInvites(contacts, templateId) {
		try {
			const authToken = UserService.getAuthToken();
			const locationId = UserService.getActiveLocation().id;

			const response = await Kichiri.bulk.action(
				{ action: BULK_TYPES.reviewRequest.id, array: contacts, locationId: locationId, templateId: templateId },
				{},
				authToken
			);

			return response.data;
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Send invites in bulk from csv data
	 * @param {Array} invites An array of objects containing Name, Phone, Email, LocationId, and TemplateId
	 * @param {Integer} inviteLimit The limit of how many invites to sent
	 * @returns {Object} Api call response
	 */
	async bulkSendInvitesFromCsv(invites, inviteLimit) {
		try {
			const authToken = UserService.getAuthToken();

			let response = await Kichiri.review_request.createBulk({ invites: invites, inviteLimit: inviteLimit }, {}, authToken);
			return response.data;
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Send the Bulk Invites Email
	 * @param {String} msgContent Errors in csv as string
	 * @param {String} fileContent Original csv data as string
	 * @param {*} numInvites Number of successful invites sent
	 * @param {*} numFailedInvites Number of failed invites
	 * @param {*} csvHasHeader If the user marked the csv as having a header
	 * @returns {Object} Api call response
	 */
	async bulkSendInvitesEmail(msgContent, fileContent, numInvites, numFailedInvites, csvHasHeader) {
		try {
			const authToken = UserService.getAuthToken();
			let response = await Kichiri.email.sendBulkInviteEmail(
				{
					msgContent: msgContent,
					fileContent: fileContent,
					numInvites: numInvites,
					numFailedInvites: numFailedInvites,
					locationName: UserService.getActiveLocation().name,
					companyName: UserService.getActiveCompany().name,
					csvHasHeader: csvHasHeader
				},
				{},
				authToken
			);
			return response;
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Send a message in bulk
	 * @param {Array} contacts An array of contacts. The object contain the name, phone, email, and locationId
	 * @param {String} messageText
	 * @param {Integer} templateId
	 * @param {Object} options
	 * @returns {Object} Api call response data
	 */
	async bulkSendMessages(contacts, messageText, templateId, options) {
		try {
			const authToken = UserService.getAuthToken();
			const locationId = UserService.getActiveLocation().id;

			const bulkResponse = await Kichiri.bulk.action(
				{ action: BULK_TYPES.sendMessage.id, array: contacts, locationId: locationId, messageText: messageText, templateId: templateId, options: options },
				{},
				authToken
			);
			return bulkResponse.data;
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Send messages in bulk from csv data
	 * @param {Array} contacts An array of objects containing Name, Phone, Email, LocationId, and TemplateId
	 * @param {Integer} inviteLimit The limit of how many messages to sent
	 * @returns {Object} Api call response
	 */
	async bulkSendMessagesFromCsv(contacts, messageText) {
		try {
			const authToken = UserService.getAuthToken();
			const locationId = UserService.getActiveLocation() ? UserService.getActiveLocation().id : "";

			let response = await Kichiri.bulk.sendMessages({ messages: contacts, locationId: locationId, messageText: messageText }, {}, authToken);
			return response.data;
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Send a Bulk Stats Email for any bulk type and action
	 * @param {String} type A BULK TYPE friendly name
	 * @param {String} action
	 * @param {Integer} total
	 * @param {Integer} numSuccesses
	 * @param {Integer} numErrors
	 * @param {Array} errors Errors that are being reported on
	 * @param {String} csvString
	 * @param {Boolean} csvHasHeader
	 */
	async sendBulkStatusEmail(type, action = "send", total, numSuccesses, numErrors, errors, csvString, csvHasHeader) {
		try {
			let title = `Bulk ${action} ${type}`;
			//Parse errors as a CSV string
			var errCsvRows = "";
			for (let i = 0; i < errors.length; i++) {
				let obj = errors[i];
				let errorFields = Object.keys(obj);
				let res = [];
				errorFields.forEach(key => {
					if (errors[i][key]) res.push(errors[i][key]);
				});
				let row = res.join(",");
				errCsvRows += row + "\r\n";
			}
			let body = {
				title: title,
				msgContent: errCsvRows,
				fileContent: csvString,
				totalActions: total,
				numActions: numSuccesses,
				numFailedActions: numErrors,
				locationName: UserService.getActiveLocation().name,
				companyName: UserService.getActiveCompany().name,
				csvHasHeader: csvHasHeader
			};
			const authToken = UserService.getAuthToken();
			await Kichiri.bulk.sendBulkStatusEmail(body, {}, authToken);
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Get the bulk limit depending on the bulk type
	 * @param {String} type A BULK TYPE id
	 */
	async getRemainingBulkLimitByType(type) {
		try {
			const authToken = UserService.getAuthToken();
			const locationId = UserService.getActiveLocation().id;

			let bulkResponse = null;
			if (type === BULK_TYPES.reviewRequest.id || type === BULK_TYPES.sendMessage.id) {
				bulkResponse = await Kichiri.bulk.getBulkActionLimitCount({}, { location: locationId, type: type }, authToken);
			}

			if (!bulkResponse) {
				throw new Error(`Unable to get bulk limit for type: '${type}'`);
			}
			let response = {
				remainingBulkCount: bulkResponse.data.remaining,
				sentBulkCount: bulkResponse.data.actionLimit - bulkResponse.data.remaining,
				bulkLimit: bulkResponse.data.actionLimit
			};
			response.customerMessage = this.getBulkRemainingCustomerMessage(type, response);
			return response;
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Get a message for the customer for bulk actions remaining
	 * @param {String} type
	 * @param {Object} response
	 * @returns {String}
	 */
	getBulkRemainingCustomerMessage(type, response) {
		try {
			let customerMessage = "";
			if (typeof response.sentBulkCount === "undefined") {
				return customerMessage;
			}

			customerMessage += response.remainingBulkCount > 0 ? response.remainingBulkCount : 0;
			if (type === BULK_TYPES.reviewRequest.id) {
				customerMessage += ` bulk review invites available. You've sent ` + response.sentBulkCount + " out of " + response.bulkLimit + " invites this week.";
			} else if (type === BULK_TYPES.sendMessage.id) {
				customerMessage += ` bulk messages available. You've created ` + response.sentBulkCount + " out of " + response.bulkLimit + " messages today.";
			}

			return customerMessage;
		} catch (error) {
			console.log(error);
			throw error;
		}
	},

	/**
	 * Upload a set of contacts. Used for uploading contacts from a csv
	 * @param {Array} contacts Contains object with name, phone, email, and locationId
	 * @returns {Object}
	 */
	async uploadContacts(contacts, options) {
		try {
			const authToken = UserService.getAuthToken();
			const locationId = UserService.getActiveLocation().id;

			const bulkResponse = await Kichiri.bulk.action({ action: BULK_TYPES.contactUpload.id, array: contacts, locationId, options }, {}, authToken);
			return bulkResponse.data;
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Get how many bulk customer messages are remaining for Scheduled Messages
	 *
	 * @param {Date} date
	 */
	async getScheduledMessageLimit(date) {
		try {
			const authToken = UserService.getAuthToken();
			const locationId = UserService.getActiveLocation().id;

			let response = await Kichiri.bulk.getBulkActionLimitCount(
				{},
				{
					location: locationId,
					type: BULK_TYPES.sendMessage.id,
					sendDate: date
				},
				authToken
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Upload a set of appointments. Used for uploading appointments from a csv
	 * @param {Array} appointments
	 * @param {Object} options
	 * @returns {Object}
	 */
	async uploadAppointments({ appointments, options }) {
		try {
			const authToken = UserService.getAuthToken();
			const locationId = UserService.getActiveLocation().id;

			const bulkResponse = await Kichiri.bulk.action({ action: BULK_TYPES.appointmentUpload.id, array: appointments, locationId, options }, {}, authToken);
			return bulkResponse.data;
		} catch (error) {
			throw error;
		}
	}
};

export default BulkSendService;
