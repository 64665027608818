import React from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import ContentLoader from "react-content-loader";
import { getServices } from "service-fetch";

import GAService from "../../../services/GAService";
import ToastService from "../../../services/ToastService";

import DHModal from "../../../components/common/DHModal";
import Tabs from "../../../components/common/Tabs";
import Tab from "../../../components/common/Tab";

import "./sign-ups.css";

const TABS = {
	timeline: {
		value: "timeline",
		label: "Timeline"
	}
};

const { Sign_upsService: SignUpService } = getServices();

class SignUpDetailsModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,

			selectedTab: TABS.timeline.value,
			signUp: null,
			events: null
		};
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.resetComponent();
	}

	componentDidUpdate(prevProps) {
		let { show } = this.props;

		if (prevProps.show !== show) {
			this.resetComponent();
		}
	}

	resetComponent = async () => {
		await this.fetchData();
	};

	fetchData = async () => {
		let { signUpId } = this.props;

		if (!signUpId) {
			return;
		}

		await this.update({
			loading: true
		});

		let { data: signUp } = await SignUpService.fetchSignUp({ params: { signUpId } });

		if (!signUp) {
			ToastService.error("Error getting sign up. Please try again.");
			await this.update({
				loading: false
			});
			return null;
		}

		let events = signUp.events;
		events.unshift({ event: "Created", timestamp: moment(signUp.created_at) });
		events.map(e => (e.event = e.event.replace("_", " ")));

		await this.update({
			loading: false,
			signUp,
			events
		});
	};

	onClose = async () => {
		if (this.props.onClose) {
			await this.props.onClose();
		}
	};

	renderLoading = () => {
		return (
			<div className="msu__form">
				<ContentLoader viewBox="0 0 100% 956" height={956} width={"100%"}>
					{/* Google Search Input */}
					<rect x="0" y="0" rx="5" ry="5" width="100%" height="60" />

					{/* Business details section */}
					<rect x="0" y="75" rx="5" ry="5" width="100%" height="568" />

					{/* Contact details section */}
					<rect x="0" y="668" rx="5" ry="5" width="100%" height="268" />
				</ContentLoader>
			</div>
		);
	};

	renderTimeline = () => {
		let { events, signUp } = this.state;

		if (!events) {
			return null;
		}

		return (
			<div className="prd-timeline">
				<div className="prd-timeline-title"></div>
				<div className="prd-timeline-details">
					{events.map((anEvent, index) => {
						return (
							<div key={`event-${signUp.id}-${index}`}>
								<div className="prd-timeline-details-line" />
								<div className="prd-timeline-details-event">
									<div className="prd-timeline-details-event-name">
										{anEvent.event} <br />
										{moment(anEvent.timestamp).format("ddd, MMM Do YYYY, h:mm a")}
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		);
	};

	renderTabs = () => {
		let { selectedTab } = this.state;

		return (
			<Tabs onSelect={this.onTabSelect} selected={selectedTab}>
				<Tab id={TABS.timeline.value} value={TABS.timeline.label} />
			</Tabs>
		);
	};

	render = () => {
		let { selectedTab, signUp } = this.state;
		let { show } = this.props;

		let title = "Sign Up Details";

		if (signUp) {
			title = signUp.legal_business_name + " Details";
		}

		return (
			<DHModal show={show} onHide={this.onClose} title={title}>
				{this.renderTabs()}

				{selectedTab === TABS.timeline.value && this.renderTimeline()}
			</DHModal>
		);
	};
}

export default withRouter(SignUpDetailsModal);
