import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import * as Icon from "react-feather";
import moment from "moment";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, parseDate } from "react-day-picker/moment";
import ReactTooltip from "react-tooltip";
import queryString from "query-string";
import { withTranslation } from "react-i18next";

import UserService from "../../services/UserService";
import AppointmentsService from "../../services/AppointmentsService";
import UtilityService from "../../services/UtilityService";
import ToastService from "../../services/ToastService";
import GAService from "../../services/GAService";
import Preferences from "../../services/PreferenceService";
import NotificationService from "../../services/NotificationService";

import Page from "../../components/common/Page";
import Header from "../../components/common/Header";
import SearchInput from "../../components/common/SearchInput";
import Filters from "../../components/common/Filters";
import List from "../../components/common/List";
import withLocation from "../../components/common/WithLocation";
import Action from "../../components/common/Action";
import Alert from "../../components/common/Alert";
import Tabs from "../../components/common/Tabs";
import Tab from "../../components/common/Tab";
import AppointmentDetails from "./AppointmentDetails";

import BulkAppointmentUpload from "../Bulk/Appointments/BulkAppointmentUpload";

import {
	STATUS,
	CONFIRM_STATES,
	APPOINTMENT_TABS,
	APPOINTMENT_COLUMNS,
	NOTIFICATION_STATUS,
	SORT_ORDER,
	APPOINTMENT_NOTIFICATION_COLUMNS,
	NOTIFICATION_TYPES
} from "../../constants/Appointments";

import "../../styles/css/scenes/appointments.css";

class Appointments extends Component {
	constructor(props) {
		super(props);

		let {
			searchTerm,
			sortField,
			sortOrder,
			dateRangeStart,
			dateRangeEnd,
			statusSelected,
			confirmSelected,
			notificationStatusSelected,
			notificationTypeSelected
		} = queryString.parse(this.props.location.search);

		if (dateRangeStart) {
			dateRangeStart = moment(dateRangeStart).toDate();
		}

		if (Preferences.get("appointments_start_date")) {
			let startDate = Preferences.get("appointments_start_date");
			if (moment(startDate.expireOn).isBefore(moment())) {
				Preferences.clear("appointments_start_date");
			}

			dateRangeStart = moment(startDate.date).toDate();
		}

		if (!dateRangeStart) {
			dateRangeStart = moment()
				.subtract(30, "days")
				.toDate();
		}

		if (dateRangeEnd) {
			dateRangeEnd = moment(dateRangeEnd).toDate();
		}

		if (!dateRangeEnd && Preferences.get("appointments_end_date")) {
			let endDate = Preferences.get("appointments_end_date");
			if (moment(endDate.expireOn).isBefore(moment())) {
				Preferences.clear("appointments_end_date");
			}
			dateRangeEnd = moment(endDate.date).toDate();
		}

		if (!dateRangeEnd) {
			dateRangeEnd = moment().toDate();
		}

		this.state = {
			loading: true,
			loadedAll: true,
			data: [],
			searchTerm: searchTerm || "",
			sortField: sortField,
			sortOrder: sortOrder ? sortOrder : SORT_ORDER.desc,
			limitDefault: 10,
			limit: 10,
			pageSize: 50,

			tabSelected: APPOINTMENT_TABS.appointments.id,
			statusSelected: statusSelected ? statusSelected : Object.keys(STATUS)[0],
			confirmSelected: confirmSelected ? confirmSelected : Object.keys(CONFIRM_STATES)[0],
			notificationStatusSelected: notificationStatusSelected ? notificationStatusSelected : Object.keys(NOTIFICATION_STATUS)[0],
			notificationTypeSelected: notificationTypeSelected ? notificationTypeSelected : Object.keys(NOTIFICATION_TYPES)[0],
			dateRangeStart: dateRangeStart,
			dateRangeEnd: dateRangeEnd,

			selectedAppointmentId: null,
			showAppointmentDetails: false,

			showChangeStatusModal: false,
			selectedNotification: null,
			selectedNotificationStatus: null,

			showBulkUploadAppointments: false
		};

		this.datePickerTo = React.createRef();
	}

	async componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.setTabAndRoute();

		await this.fetchData();
		NotificationService.subscribe("onUrlChange", ({ location, action }) => {
			this.setTabAndRoute();
		});
	}

	onLocationChanged = async location => {
		await this.fetchData();
	};

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	resetComponent = () => {
		this.update({
			showChangeStatusModal: false,
			selectedNotification: null,
			selectedNotificationStatus: null
		});
	};

	setTabAndRoute = async () => {
		let {
			searchTerm,
			sortField,
			sortOrder,
			dateRangeStart,
			dateRangeEnd,
			statusSelected,
			confirmSelected,
			notificationStatusSelected,
			notificationTypeSelected
		} = queryString.parse(this.props.location.search);

		let tab = APPOINTMENT_TABS.appointments.id;
		let route = APPOINTMENT_TABS.appointments.route;

		let tabs = Object.values(APPOINTMENT_TABS);
		let routes = tabs.map(t => t.route);
		let routeIndex = routes.indexOf(this.props.location.pathname);

		if (dateRangeStart) {
			dateRangeStart = moment(dateRangeStart).toDate();
		}
		if (dateRangeEnd) {
			dateRangeEnd = moment(dateRangeEnd).toDate();
		}

		if (routeIndex < 0) {
			routeIndex = 0;
		}

		route = routes[routeIndex];
		tab = tabs[routeIndex].id;

		let updateObj = { tabSelected: tab };

		if (searchTerm) {
			updateObj.searchTerm = searchTerm;
		}
		if (sortField) {
			updateObj.sortField = sortField;
		}
		if (sortOrder) {
			updateObj.sortOrder = sortOrder;
		}
		if (dateRangeStart) {
			updateObj.dateRangeStart = dateRangeStart;
		}
		if (dateRangeEnd) {
			updateObj.dateRangeEnd = dateRangeEnd;
		}
		if (statusSelected) {
			updateObj.statusSelected = statusSelected;
		}
		if (confirmSelected) {
			updateObj.confirmSelected = confirmSelected;
		}
		if (notificationStatusSelected) {
			updateObj.notificationStatusSelected = notificationStatusSelected;
		}
		if (notificationTypeSelected) {
			updateObj.notificationTypeSelected = notificationTypeSelected;
		}

		this.update(updateObj);

		return route;
	};

	setUrlParams() {
		if (!this.props.history) {
			return;
		}

		let {
			searchTerm,
			sortField,
			sortOrder,
			dateRangeStart,
			dateRangeEnd,
			statusSelected,
			confirmSelected,
			notificationStatusSelected,
			notificationTypeSelected
		} = this.state;

		let params = {};

		if (searchTerm) {
			params.searchTerm = searchTerm;
		}

		if (sortField) {
			params.sortField = sortField;
		}

		if (sortOrder) {
			params.sortOrder = sortOrder;
		}

		if (dateRangeStart) {
			params.dateRangeStart = moment(dateRangeStart).format("YYYY-MM-DD");
		}

		if (dateRangeEnd) {
			params.dateRangeEnd = moment(dateRangeEnd).format("YYYY-MM-DD");
		}

		if (statusSelected) {
			params.statusSelected = statusSelected;
		}

		if (confirmSelected) {
			params.confirmSelected = confirmSelected;
		}

		if (notificationStatusSelected) {
			params.notificationStatusSelected = notificationStatusSelected;
		}

		if (notificationTypeSelected) {
			params.notificationTypeSelected = notificationTypeSelected;
		}

		params = new URLSearchParams(params);
		this.props.history.replace({
			pathname: this.props.location.pathname,
			search: params.toString()
		});
	}

	async fetchData() {
		if (this.isAppointmentMode()) {
			await this.fetchAppointments();
		} else {
			await this.fetchNotifications();
		}
		this.resetComponent();
	}

	async fetchNotifications() {
		let { searchTerm, notificationStatusSelected, notificationTypeSelected, sortField, sortOrder, limit, dateRangeStart, dateRangeEnd } = this.state;

		await this.update({ loading: true });

		let location = UserService.getActiveLocation();
		let data = await AppointmentsService.fetchAppointmentNotifications(location.id, {
			status: notificationStatusSelected,
			type: notificationTypeSelected,
			searchTerm,
			sortField,
			sortOrder,
			limit,
			sendAfterStartDate: dateRangeStart,
			sendAfterEndDate: dateRangeEnd
		});

		await this.update({
			data,
			loadedAll: data.length < limit,
			loading: false
		});
	}

	async fetchAppointments() {
		let { searchTerm, statusSelected, confirmSelected, sortField, sortOrder, limit, dateRangeStart, dateRangeEnd } = this.state;

		await this.update({ loading: true });

		let location = UserService.getActiveLocation();
		let data = await AppointmentsService.fetchAppointments({
			locationId: location.id,
			state: statusSelected,
			confirmed: confirmSelected,
			searchTerm,
			sortField,
			sortOrder,
			limit,
			bookingAtStartDate: dateRangeStart,
			bookingAtEndDate: dateRangeEnd
		});

		await this.update({
			data,
			loadedAll: data.length < limit,
			loading: false
		});
	}

	isAppointmentMode = () => {
		return this.state.tabSelected && this.state.tabSelected === APPOINTMENT_TABS.appointments.id;
	};

	onSearchChange = async value => {
		await this.update({
			searchTerm: value
		});
		this.setUrlParams();
		await this.fetchData();
	};

	isStatusSelected = item => {
		return item === this.state.statusSelected;
	};

	onAppStatusSelect = async item => {
		await this.update({ statusSelected: item.id });
		this.setUrlParams();
		await this.fetchData();
	};

	isNotificationStatusSelected = filter => {
		return filter === this.state.notificationStatusSelected;
	};

	onNotificationStatusChange = async item => {
		await this.update({ notificationStatusSelected: item.id });
		this.setUrlParams();
		await this.fetchData();
	};

	isNotificationTypeSelected = filter => {
		return filter === this.state.notificationTypeSelected;
	};

	onNotificationTypeChange = async item => {
		await this.update({ notificationTypeSelected: item.id });
		this.setUrlParams();
		await this.fetchData();
	};

	isConfirmStateSelected = item => {
		return item === this.state.confirmSelected;
	};

	onConfirmSelect = async item => {
		await this.update({ confirmSelected: item.id });
		this.setUrlParams();
		await this.fetchData();
	};

	handleFromChange = async dateRangeStart => {
		// Save the preference until the end of the day
		Preferences.set("appointments_start_date", { date: moment(dateRangeStart).toDate(), expireOn: moment().endOf("day") });
		await this.update({ dateRangeStart });
		this.setUrlParams();
		await this.fetchData();
	};

	handleToChange = async dateRangeEnd => {
		// Save the preference until the end of the day
		Preferences.set("appointments_end_date", { date: moment(dateRangeEnd).toDate(), expireOn: moment().endOf("day") });
		await this.update({ dateRangeEnd });
		this.setUrlParams();
		this.showFromMonth();
		await this.fetchData();
	};

	showFromMonth() {
		const { dateRangeStart, dateRangeEnd } = this.state;
		if (!dateRangeStart) {
			return;
		}
		if (moment(dateRangeEnd).diff(moment(dateRangeStart), "months") < 2) {
			this.datePickerTo.getDayPicker().showMonth(dateRangeStart);
		}
	}

	onLoadMore = async () => {
		let { limit, pageSize } = this.state;

		await this.update({
			limit: limit + pageSize
		});

		await this.fetchData();
	};

	onHideAppointmentDetailsModal = async () => {
		await this.update({
			selectedAppointmentId: null,
			showAppointmentDetails: false
		});
	};

	onRecordClicked = async item => {
		if (this.isAppointmentMode()) {
			await this.update({
				selectedAppointmentId: item.id,
				showAppointmentDetails: true
			});
		} else {
			await this.update({
				selectedAppointmentId: item.SyncedAppointment.id,
				showAppointmentDetails: true
			});
		}
	};

	toggleUploadAppointments = async () => {
		let { showBulkUploadAppointments } = this.state;

		await this.update({
			showBulkUploadAppointments: !showBulkUploadAppointments
		});
	};

	sortBy = async sortField => {
		let { sortOrder } = this.state;

		sortOrder = sortOrder === SORT_ORDER.asc ? SORT_ORDER.desc : SORT_ORDER.asc;

		await this.update({ sortField, sortOrder });
		this.setUrlParams();
		await this.fetchData();
	};

	onAppNotificationsChange = async item => {
		Preferences.set("appointments_selected_tab", item.id);
		await this.update({
			tabSelected: item.id,
			sortField: item.id === APPOINTMENT_TABS.appointments.id ? APPOINTMENT_COLUMNS.booking_at.id : APPOINTMENT_NOTIFICATION_COLUMNS.send_after.sortField,
			sortOrder: SORT_ORDER.desc,
			limit: this.state.limitDefault,
			loadedAll: true
		});
		this.props.history.push(APPOINTMENT_TABS[item.id].route);
		this.setTabAndRoute();
		this.setUrlParams();

		await this.fetchData();
	};

	getAppointmentFilters = () => {
		let { t } = this.props;

		let statuses = Object.keys(STATUS)
			.map(item => {
				return { id: item, value: STATUS[item].display, order: STATUS[item].order };
			})
			.sort((a, b) => a.order - b.order);

		let confirmStates = Object.keys(CONFIRM_STATES)
			.map(item => {
				return { id: CONFIRM_STATES[item].id, value: CONFIRM_STATES[item].display, order: CONFIRM_STATES[item].order };
			})
			.sort((a, b) => a.order - b.order);

		let filters = {
			statuses: {
				title: t("Status"),
				items: statuses,
				onClick: this.onAppStatusSelect,
				isSelected: this.isStatusSelected
			},
			confirmStates: {
				title: t("Confirmed"),
				items: confirmStates,
				onClick: this.onConfirmSelect,
				isSelected: this.isConfirmStateSelected
			}
		};
		return filters;
	};

	getSelectableDataTypes() {
		let location = UserService.getActiveLocation();

		if (!(location.LocationFeature.appointment_confirmations || location.LocationFeature.appointment_reminders)) {
			return [{ value: APPOINTMENT_TABS.appointments.id, label: APPOINTMENT_TABS.appointments.display }];
		}

		let appointments = Object.keys(APPOINTMENT_TABS).map(item => {
			return { value: APPOINTMENT_TABS[item].id, label: APPOINTMENT_TABS[item].display };
		});

		return appointments;
	}

	getAppointmentNotificationFilters = () => {
		let statuses = Object.keys(NOTIFICATION_STATUS)
			.map(item => {
				return { id: NOTIFICATION_STATUS[item].id, value: NOTIFICATION_STATUS[item].display, order: NOTIFICATION_STATUS[item].order };
			})
			.sort((a, b) => a.order - b.order);

		let types = Object.keys(NOTIFICATION_TYPES)
			.map(item => {
				return { id: NOTIFICATION_TYPES[item].id, value: NOTIFICATION_TYPES[item].display, order: NOTIFICATION_TYPES[item].order };
			})
			.sort((a, b) => a.order - b.order);

		let filters = {
			statuses: {
				title: "Status",
				items: statuses,
				onClick: this.onNotificationStatusChange,
				isSelected: this.isNotificationStatusSelected
			},
			types: {
				title: "Type",
				items: types,
				onClick: this.onNotificationTypeChange,
				isSelected: this.isNotificationTypeSelected
			}
		};
		return filters;
	};

	getFilters = () => {
		if (this.isAppointmentMode()) {
			return this.getAppointmentFilters();
		} else {
			return this.getAppointmentNotificationFilters();
		}
	};

	getHeaders = () => {
		if (this.isAppointmentMode()) {
			let columns = APPOINTMENT_COLUMNS;

			let headers = {
				items: columns,
				sortBy: this.sortBy
			};

			return headers;
		}

		let columns = APPOINTMENT_NOTIFICATION_COLUMNS;

		let headers = {
			items: columns,
			sortBy: this.sortBy
		};

		return headers;
	};

	onActionClicked = async (recordData, status) => {
		await this.update({
			showChangeStatusModal: true,
			selectedNotification: recordData,
			selectedNotificationStatus: status
		});
	};

	onCloseChangeStatusModal = async confirm => {
		let { t } = this.props;

		if (!confirm) {
			return;
		}

		let { selectedNotification, selectedNotificationStatus } = this.state;

		let data = await AppointmentsService.updateAppointmentNotification({ id: selectedNotification.id, status: selectedNotificationStatus });

		if (!data) {
			ToastService.error(t("An error occurred trying to update the appointment notification. Please try again."));
		} else {
			ToastService.info(t("Appointment Notification saved."));
		}
		this.fetchData();
	};

	onAppointmentUploadCompleted = async uploadedAppointments => {
		await this.update({ showBulkUploadAppointments: false });
		await this.fetchData();
	};

	renderAppointmentRecord(recordData) {
		try {
			if (!recordData) {
				return null;
			}
			var fullName = UserService.createFullName({ firstName: recordData.first_name, lastName: recordData.last_name });

			let { localTimeZoneString } = UtilityService.getTimeZoneHelpers();

			return [
				fullName,
				STATUS[recordData.state] ? STATUS[recordData.state].display : recordData.state,
				recordData.confirmed ? <Icon.Check size="24" /> : "",
				recordData.type,
				recordData.provider_name,
				`${recordData.AppointmentNotifications ? recordData.AppointmentNotifications.filter(n => n.status === "pending").length : 0}`,
				recordData.booking_at ? (
					<span className="dh-tip" tip={localTimeZoneString}>
						{moment(recordData.booking_at).format("ddd, MMM Do YYYY, h:mm a")}
					</span>
				) : (
					""
				),
				recordData.created_at ? moment(recordData.created_at).format("MMM Do YYYY, h:mm a") : ""
			];
		} catch (error) {
			console.log(error.stack);
		}
		return null;
	}

	renderAppointmentNotificationRecord(recordData) {
		let { t } = this.props;

		try {
			if (!recordData || !recordData.SyncedAppointment) {
				return null;
			}

			let name = "";
			if (recordData && recordData.SyncedAppointment) {
				var syncedUser = recordData.SyncedAppointment;
				name = UserService.createFullName({ firstName: syncedUser.first_name, lastName: syncedUser.last_name });
			}

			return [
				name,
				NOTIFICATION_TYPES[recordData.type].display,
				NOTIFICATION_STATUS[recordData.status].display,
				recordData.send_after ? moment(recordData.send_after).format("MMM Do YYYY, h:mm a") : "",
				recordData.created_at ? moment(recordData.created_at).format("MMM Do YYYY, h:mm a") : "",
				<div className="dh-list__actions">
					{recordData.status !== NOTIFICATION_STATUS.cancelled.id && recordData.status !== NOTIFICATION_STATUS.sent.id && (
						<Action
							key={`cancel-${recordData.id}`}
							id={`cancel-${recordData.id}`}
							label={t("Cancel")}
							icon={Icon.X}
							onClick={() => this.onActionClicked(recordData, NOTIFICATION_STATUS.cancelled.id)}
						/>
					)}
					{recordData.status === NOTIFICATION_STATUS.cancelled.id && (
						<Action
							key={`pending-${recordData.id}`}
							id={`pending-${recordData.id}`}
							label={t("Pending")}
							icon={Icon.Repeat}
							onClick={() => this.onActionClicked(recordData, NOTIFICATION_STATUS.pending.id)}
						/>
					)}
				</div>
			];
		} catch (error) {
			console.log(error.stack);
		}
		return null;
	}

	renderRecord = recordData => {
		if (this.isAppointmentMode()) {
			return this.renderAppointmentRecord(recordData);
		} else {
			return this.renderAppointmentNotificationRecord(recordData);
		}
	};

	renderDateRangeFilter = () => {
		let { dateRangeStart, dateRangeEnd } = this.state;
		let { t } = this.props;

		const modifiers = { dateRangeStart, dateRangeEnd };

		return (
			<div className="appointments__date-range">
				<div className="appointments__date-range__text" data-tip data-for="date-range-selector-rtt">
					{t("Date Range")} <Icon.Info size={13} />
					<ReactTooltip id="date-range-selector-rtt" className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="bottom">
						{this.isAppointmentMode() ? t("Filter On Booking At Date") : t("Filter On Send After Date")}
					</ReactTooltip>
				</div>
				<div className="input-group">
					<div className="InputFromTo">
						<DayPickerInput
							value={dateRangeStart}
							placeholder={t(" From")}
							format="LL"
							formatDate={formatDate}
							parseDate={parseDate}
							dayPickerProps={{
								selectedDays: [dateRangeStart, { from: dateRangeStart, to: dateRangeEnd }],
								disabledDays: { after: dateRangeEnd },
								toMonth: dateRangeEnd,
								modifiers,
								numberOfMonths: 2,
								onDayClick: () => this.datePickerTo.getInput().focus()
							}}
							onDayChange={this.handleFromChange}
						/>{" "}
						<span>
							<DayPickerInput
								ref={el => (this.datePickerTo = el)}
								value={dateRangeEnd}
								placeholder={t(" To")}
								format="LL"
								formatDate={formatDate}
								parseDate={parseDate}
								dayPickerProps={{
									selectedDays: [dateRangeStart, { from: dateRangeStart, to: dateRangeEnd }],
									disabledDays: { before: dateRangeStart },
									modifiers,
									month: dateRangeStart,
									fromMonth: dateRangeStart,
									numberOfMonths: 2
								}}
								onDayChange={this.handleToChange}
							/>
						</span>
					</div>
				</div>
			</div>
		);
	};

	render() {
		let {
			data,
			loading,
			loadedAll,
			tabSelected,
			searchTerm,
			sortField,
			sortOrder,
			showChangeStatusModal,
			showAppointmentDetails,
			selectedAppointmentId,
			showBulkUploadAppointments
		} = this.state;
		let { t } = this.props;
		let location = UserService.getActiveLocation();

		let isSuperAdminOrCustomerSuccess = UserService.isSuperAdminOrCustomerSuccess();

		return (
			<Page>
				<Header title={t("Appointments")} />
				<Tabs onSelect={this.onAppNotificationsChange} selected={tabSelected}>
					<Tab id={APPOINTMENT_TABS.appointments.id} value={APPOINTMENT_TABS.appointments.display} />
					{(location.LocationFeature.appointment_confirmations || location.LocationFeature.appointment_reminders) && (
						<Tab id={APPOINTMENT_TABS.appointmentNotifications.id} value={APPOINTMENT_TABS.appointmentNotifications.display} />
					)}
				</Tabs>
				<div className="appointments__header_controls">
					<div className="appointments-search">
						<SearchInput placeholder={t("Search ...")} onChange={this.onSearchChange} initValue={searchTerm} leading={false} />
					</div>
					{isSuperAdminOrCustomerSuccess && (
						<div className="appointments__header__actions">
							<Action id={`upload-appointments`} label={"Upload Appointments"} icon={Icon.UploadCloud} onClick={() => this.toggleUploadAppointments()} />
						</div>
					)}
				</div>
				{this.renderDateRangeFilter()}
				<Filters filters={this.getFilters()} />
				<List
					items={data}
					loading={loading}
					loadedAll={loadedAll}
					sortField={sortField}
					sortOrder={sortOrder}
					headers={this.getHeaders()}
					renderRecord={this.renderRecord}
					onRecordClicked={this.onRecordClicked}
					onLoadMore={this.onLoadMore}
					noDataTitle={t("Looks like there are no appointments yet...")}
					noDataIcon={<Icon.AlertCircle />}
				/>
				<Alert
					type="warning"
					show={showChangeStatusModal}
					title={t("Are you sure?")}
					confirm={t("Yes")}
					cancel={t("No")}
					onClose={confirm => this.onCloseChangeStatusModal(confirm)}
				>
					<div>{t("Are you sure you would like to change the status of this appointment notification?")}</div>
				</Alert>
				<AppointmentDetails show={showAppointmentDetails} appointmentId={selectedAppointmentId} onHide={() => this.onHideAppointmentDetailsModal()} />
				<BulkAppointmentUpload
					show={showBulkUploadAppointments}
					onClose={this.toggleUploadAppointments}
					title="Upload Appointments to DemandHub"
					location={location}
					onCompleted={this.onAppointmentUploadCompleted}
				/>
			</Page>
		);
	}
}

export default withRouter(withLocation(withTranslation(null, { withRef: true })(Appointments)));
