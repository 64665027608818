import React, { Component } from "react";
import { AnalyticsService } from "../../services/AnalyticsService";
import UserService from "../../services/UserService";
import ReactTooltip from "react-tooltip";
import * as Icon from "react-feather";

import withLocation from "../../components/common/WithLocation";

class TotalReviews extends Component {
	state = { totalReviews: 0 };

	componentDidMount() {
		this.fetchTotalReviews();
	}

	onLocationChanged = () => {
		this.fetchTotalReviews();
	};

	async fetchTotalReviews() {
		try {
			const locationId = UserService.getActiveLocation().id;
			const totalReviews = await AnalyticsService.getReviewCountForLocation({ locationId });
			this.setState({ totalReviews });
		} catch (error) {
			console.log(error);
			this.setState({ totalReviews: 0 });
		}
	}

	render() {
		const { totalReviews } = this.state;
		const { cardName, isEnabled, isPermissible } = this.props;

		return (
			<>
				<div className="dashboard__card__header">
					<div className="dashboard__card__header__icon">
						<Icon.Star size={42} />
					</div>
					<div className="dashboard__card__header__name dashboard__card__header__name--margin-left">
						{cardName ? cardName : "Total Reviews"} <Icon.Info size={20} data-tip data-for="total-reviews-all-sites" />
						<ReactTooltip id="total-reviews-all-sites" className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="right">
							Total reviews across all sites.
						</ReactTooltip>
					</div>
				</div>
				<div className="dashboard__card__stat">
					{isEnabled && isEnabled() && isPermissible && isPermissible() ? (
						<>
							<div className="dashboard__card__stat__count">{totalReviews}</div>
							<small>updated nightly</small>
						</>
					) : (
						`${isEnabled() ? "Reviews are not enabled. Contact support to get started." : "Review Data Restricted."}`
					)}
				</div>
			</>
		);
	}
}

export default withLocation(TotalReviews);
