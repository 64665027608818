import React, { Component } from "react";
import Select from "react-select";
import * as Icon from "react-feather";
import ReactTooltip from "react-tooltip";

import DHModal from "../../components/common/DHModal";
import Input from "../../components/common/Input";
import Alert from "../../components/common/Alert";
import DHSelect from "../../components/common/DHSelect";

import ToastService from "../../services/ToastService";
import CrmIntegrationsService from "../../services/CrmIntegrationsService";
import UserService from "../../services/UserService";

import { CRM_INTEGRATIONS } from "../../constants/CRMIntegration";
import { STATUS } from "../../constants/CommonConstants";

import "../../styles/css/scenes/connections.css";

class ConnectSalesforceModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			selectedIntegration: null,
			selectableIntegrations: [],
			integration: null,

			instanceUrl: "",
			clientId: "",
			clientSecret: "",

			crmLocationId: 0,
			crmLocationName: "",
			locations: [],
			showLocationSelector: false,
			showAlertNoLocationsFound: false,
			showAlertLocationUpdateFailed: false,

			lastTestStatus: false,
			showAlertIntegrationNotFound: false,
			showAlertTestSuccess: false,
			showAlertTestFailed: false,
			showAlertConnectionComplete: false,
			showDisconnectModal: false
		};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	componentDidMount() {
		this.resetComponent();
	}

	componentDidUpdate(prevProps) {
		let { show } = this.props;

		if (prevProps.show !== show) {
			this.resetComponent();
		}
	}

	async resetComponent() {
		let { show } = this.props;

		// If we are not showing the dialog, don't fetch anything
		if (!show) {
			return;
		}

		await this.fetchIntegrations();
		await this.onIntegrationSelected();
	}

	onClickDisconnect = () => {
		this.update({ showDisconnectModal: true });
	};

	disconnectIntegration = async confirm => {
		if (!confirm) {
			await this.update({ showDisconnectModal: false });
			return;
		}

		if (this.props.disconnectIntegration) {
			this.props.disconnectIntegration();
		}

		await this.update({ showDisconnectModal: false });
	};

	fetchIntegrations = async () => {
		let integrations = await CrmIntegrationsService.fetch({
			locationId: UserService.getActiveLocation().id,
			params: {
				type: CRM_INTEGRATIONS.type.salesforce.name,
				status: [STATUS.active]
			},
			ignoreCache: true
		});

		if (!integrations) {
			await this.update({
				selectableIntegrations: []
			});
			return;
		}

		let selectableIntegrations = integrations.map(i => {
			return { value: i.id, label: i.name ? i.name : CRM_INTEGRATIONS[i.crm_type].displayName };
		});

		await this.update({
			selectableIntegrations
		});
	};

	onIntegrationSelected = async integrationOption => {
		let integrationId = null;

		if (integrationOption) {
			integrationId = integrationOption.value;
		}

		if (!integrationId) {
			// Default to the passed in integration id
			integrationId = this.props.integrationId;
		}

		// Set loading to true, and reset dirty bit
		await this.update({
			loading: true,
			integration: null
		});

		try {
			// Fetch the integration object from the backend
			let integration = await CrmIntegrationsService.fetchRestIntegration(integrationId);

			if (!integration || integration.status === STATUS.deleted) {
				// Show integration not found dialog
				await this.update({
					showAlertIntegrationNotFound: true
				});

				return;
			}

			let instanceUrl = integration.meta_data.instanceUrl ? integration.meta_data.instanceUrl : "";
			let clientId = integration.meta_data.clientId ? integration.meta_data.clientId : "";
			let clientSecret = integration.meta_data.clientSecret ? integration.meta_data.clientSecret : "";
			let crmLocationId = integration.meta_data.locationId ? integration.meta_data.locationId : 0;
			let crmLocationName = integration.meta_data.locationName ? integration.meta_data.locationName : "";

			// Save this integration in our state
			await this.update({
				integration,
				instanceUrl,
				clientId,
				clientSecret,
				crmLocationId,
				crmLocationName,

				selectedIntegration: { value: integration.id, label: integration.name ? integration.name : CRM_INTEGRATIONS[integration.crm_type].displayName }
			});
		} catch (error) {
			console.log(error.message);
		}
		await this.update({ loading: false });
	};

	onInputChange = async event => {
		await this.update({ [event.target.name]: event.target.value, edited: true });
	};

	onTestConnection = async event => {
		let { selectedIntegration, instanceUrl, clientId, clientSecret } = this.state;

		await this.update({ loading: true });

		let testResponse = await CrmIntegrationsService.testRestIntegration({
			integrationId: selectedIntegration.value,
			tenantId: instanceUrl,
			login: clientId,
			password: clientSecret
		});

		if (testResponse.status) {
			ToastService.info(`Connection Successful!`);
			await this.update({ lastTestStatus: testResponse.status });
			await this.fetchLocations();
			return;
		}

		await this.update({ lastTestStatus: testResponse.status, loading: false, showAlertTestFailed: !testResponse.status });
	};

	onConfirmTestSuccess = async event => {
		await this.update({ showAlertTestSuccess: false });

		await this.fetchLocations();
	};

	/**
	 * Fetch the Salesforce locations
	 */
	async fetchLocations() {
		let { show } = this.props;
		let { selectedIntegration } = this.state;

		// If we are not showing the dialog, don't fetch anything
		if (!show) {
			return;
		}

		// Fetch the locations from the backend
		try {
			let locations = await CrmIntegrationsService.fetchRestLocations(selectedIntegration.value);
			if (!locations || locations.length === 0) {
				// Show locations not found dialog
				await this.update({
					showAlertNoLocationsFound: true
				});

				return;
			}

			await this.update({ locations, showLocationSelector: true, loading: false });
		} catch (error) {
			console.log(error.message);
			this.update({ showAlertTestFailed: true, showLocationSelector: false, loading: false });
		}
	}

	onConfirmTestFailed = async event => {
		await this.update({ showAlertTestFailed: false });
	};

	onConfirmIntegrationNotFound = async event => {
		await this.update({ showAlertIntegrationNotFound: false });
	};

	onConfirmNoLocationsFound = async event => {
		let { onHide } = this.props;

		await this.update({ showLocationSelector: false, showAlertNoLocationsFound: false, loading: false });

		if (onHide) {
			onHide();
		}
	};

	onConfirmLocationUpdateFailed = async event => {
		await this.update({ showAlertLocationUpdateFailed: false });
	};

	onLocationChange = async selection => {
		await this.update({ crmLocationId: selection ? selection.value : "", crmLocationName: selection.label });
	};

	onSaveLocation = async event => {
		let { crmLocationId, crmLocationName, selectedIntegration } = this.state;

		await this.update({ loading: true });

		let saveLocResponse = await CrmIntegrationsService.updateRestLocation({
			integrationId: selectedIntegration.value,
			crmLocationId,
			crmLocationName
		});

		if (!saveLocResponse) {
			await this.update({ loading: false, showAlertLocationUpdateFailed: true });
			return;
		}

		// If we get here, show the success message
		await this.update({ showAlertConnectionComplete: true });
	};

	onCancel = async event => {
		let { onHide } = this.props;

		await this.update({ showLocationSelector: false });

		if (onHide) {
			onHide();
		}
	};

	onConfirmConnectionComplete = async event => {
		let { onHide } = this.props;

		// If we get here, we're all done
		await this.update({ loading: false, showLocationSelector: false, showAlertConnectionComplete: false });

		if (onHide) {
			onHide();
		}
	};

	connectDisabled = () => {
		let { loading, instanceUrl, clientId, clientSecret } = this.state;

		if (loading) {
			return true;
		}

		if (instanceUrl === "" || clientId === "" || clientSecret === "") {
			return true;
		}

		if (!instanceUrl.includes("https://")) {
			return true;
		}

		return false;
	};

	render() {
		let { show, integrationId } = this.props;

		let {
			instanceUrl,
			clientId,
			clientSecret,

			crmLocationId,
			crmLocationName,
			locations,
			showAlertLocationUpdateFailed,
			showLocationSelector,

			showAlertTestSuccess,
			showAlertTestFailed,
			showAlertIntegrationNotFound,
			showAlertConnectionComplete,
			showAlertNoLocationsFound,
			showDisconnectModal,

			selectedIntegration,
			selectableIntegrations
		} = this.state;

		let integrationName = CRM_INTEGRATIONS.type.salesforce.displayName;

		let isSuperOrCs = UserService.isSuperAdminOrCustomerSuccess();
		let showDisconnectButton = integrationId && isSuperOrCs;

		return (
			<>
				<DHModal title={`Connect to ${integrationName}`} show={show} onHide={this.onCancel}>
					<>
						{selectableIntegrations.length > 1 && selectedIntegration && (
							<div className="Connections-modal__select">
								<div>Integration</div>
								<Select
									id={`selectable-integrations`}
									options={selectableIntegrations}
									value={selectedIntegration}
									onChange={this.onIntegrationSelected}
									placeholder={`${integrationName} Integration`}
								/>
							</div>
						)}

						{!showLocationSelector && (
							<>
								{crmLocationId && crmLocationName ? <div>Connected to location "{crmLocationName}"</div> : null}
								<div className="modal__flex-container">
									<div className="Connections-modal__input">
										<Input
											label={
												<span>
													Instance Url <Icon.Info data-tip data-for={`salesforce-instance-url-tooltip`} size={14} />
													<ReactTooltip
														id={`salesforce-instance-url-tooltip`}
														className="mb-react-tooltip"
														arrowColor="#333"
														type="info"
														effect="solid"
														place="right"
													>
														<div>Url must include 'https://'</div>
													</ReactTooltip>{" "}
												</span>
											}
											name="instanceUrl"
											id="salesforce-url"
											type="text"
											onChange={this.onInputChange}
											value={instanceUrl}
											required
										/>
									</div>
									<div className="Connections-modal__input">
										<Input label="Client Id" name="clientId" id="salesforce-client-id" type="text" onChange={this.onInputChange} value={clientId} required />
									</div>
									<div className="Connections-modal__input">
										<Input
											label="Client Secret"
											name="clientSecret"
											id="salesforce-client-secret"
											type="text"
											onChange={this.onInputChange}
											value={clientSecret}
											required
										/>
									</div>
								</div>
								<div className="modal__actions">
									<button
										id="salesforce-cancel"
										className={`mb-button mb-button--cancel ${this.state.loading ? "mb-button--disabled" : ""}`}
										disabled={this.state.loading}
										onClick={this.onCancel}
									>
										Cancel
									</button>

									{showDisconnectButton && (
										<button
											id="salesforce-disconnect"
											className={`mb-button mb-button--cancel ${this.state.loading ? "mb-button--disabled" : ""}`}
											disabled={this.state.loading}
											onClick={this.onClickDisconnect}
										>
											Disconnect
										</button>
									)}

									<button
										id="salesforce-test-connection"
										className={`mb-button ${this.connectDisabled() ? "mb-button--disabled" : ""}`}
										disabled={this.connectDisabled()}
										onClick={this.onTestConnection}
									>
										Test Connection
									</button>
								</div>
							</>
						)}

						{showLocationSelector && (
							<>
								<div className="Connections-modal__input">
									<DHSelect
										label="Locations"
										name="salesforce-location"
										id="salesforce-location"
										ref={this.locationsRef}
										onChange={this.onLocationChange}
										defaultValue={crmLocationId}
										value={{ value: crmLocationId, label: crmLocationName }}
										options={locations.map(location => {
											return {
												value: location.id,
												label: location.name
											};
										})}
										required={true}
										isClearable={true}
									/>
								</div>

								<div className="modal__actions">
									<button
										id="salesforce-cancel-location"
										className={`mb-button mb-button--cancel ${this.state.loading ? "mb-button--disabled" : ""}`}
										disabled={this.state.loading}
										onClick={this.onCancel}
									>
										Cancel
									</button>

									<button
										id="salesforce-save-location"
										className={`mb-button ${this.state.loading ? "mb-button--disabled" : ""}`}
										disabled={this.state.loading}
										onClick={this.onSaveLocation}
									>
										Save
									</button>
								</div>
							</>
						)}
					</>
				</DHModal>

				<Alert type="success" show={showAlertTestSuccess} title="Connection Successful" confirm="OK" onClose={this.onConfirmTestSuccess}>
					The connection was successful!
				</Alert>
				<Alert type="error" show={showAlertTestFailed} title="Connection Failed" confirm="OK" onClose={this.onConfirmTestFailed}>
					The connection failed. Please verify credentials and try again.
				</Alert>
				<Alert type="error" show={showAlertIntegrationNotFound} title="Not Found" confirm="OK" onClose={this.onConfirmIntegrationNotFound}>
					The {integrationName} integration was not found.
				</Alert>
				<Alert type="success" show={showAlertConnectionComplete} title="Connection Complete" confirm="OK" onClose={this.onConfirmConnectionComplete}>
					The {integrationName} connection is all set up.
				</Alert>
				<Alert type="error" show={showAlertNoLocationsFound} title="No Locations Found" confirm="OK" onClose={this.onConfirmNoLocationsFound}>
					No Salesforce locations were found.
				</Alert>
				<Alert type="error" show={showAlertLocationUpdateFailed} title="Location Failed" confirm="OK" onClose={this.onConfirmLocationUpdateFailed}>
					The location selection failed. Please try again.
				</Alert>
				<Alert type="info" show={showDisconnectModal} title={"Disconnect"} confirm={"Yes"} cancel={"No"} onClose={this.disconnectIntegration}>
					<div>{`Are you sure you would like to disconnect the integration?`}</div>
				</Alert>
			</>
		);
	}
}
export default ConnectSalesforceModal;
